.stripe-form-label {
  margin-top: 16px;
}
.payment-request-button {
  width: 100%;
  margin: 38px 0 15px 0;
  font-size: 21px !important;
  font-weight: 600;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  &--gocardless {
    width: 100%;
    margin: 38px 0 15px 0;
    padding: 5px 20px;
    font-size: 14px !important;
    .gocardless-text {
      font-size: 21px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .payment-request-button {
    font-size: 17px !important;
  }
}
