@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";

$enable-cssgrid: true;
$enable-negative-margins: true;

////Button style variables
$hm-border-radius-large: 22px;
$hm-btn-border-width: 2px;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$btn-font-family: 'Open Sans', sans-serif;
$btn-border-width: $hm-btn-border-width;
$btn-border-radius: $hm-border-radius-large;
$btn-border-radius-extra-lg: 37px;
$btn-border-radius-lg: $hm-border-radius-large;
$btn-border-radius-md: $hm-border-radius-large;
$btn-border-radius-sm: $hm-border-radius-large;
$btn-font-weight: $font-weight-regular;
$btn-padding-y: .37em;
$btn-padding-x: 2.95em;
$btn-padding-x-extra-lg: 3.75em;
$btn-padding-x-lg: 3em;
$btn-padding-x-md: 1.5em;

$modal-lg: 847px;

////Navbar style variables
$navbar-padding-y: .28em;


$primary: #0071E3;
$black1: #1D1D1F;
$black2: #23242C;
$purple1: #A62E8D;
$purple2: #B82AA9;
$purple3: #8530F7;
$purple4: #42185A;
$purple5: #3E2155;
$purple6: #B52AAA;
$purple7: #B52DAB;
$purple8: #BB2CC0;
$purple9: #BC2CC0;
$pink: #FF4F7B;
$pink2: #EB168B;
$pink3: #D33B85;
$pink4: #FE195B;
$pink5: #E31D86;
$cerise: #B82AA9;
$red: #D41417;
$red2: #D41317;
$grey1: #86868B;
$grey2: #D2D2D6;
$grey3: #F5F5F7;
$grey4: #F8F9FA;
$grey5: #6E6E73;
$grey6: #F6F6F7;
$grey7: #F9F9F9;
$grey8: #E2E2E4;
$pastel-green: #64EB81;
$teal: #2AB5B0;
$lavender: #736FDB;
$picton-blue: #41B8E5;


$yellow: #FFC72A;
$yellow2: #FFCA35;
$orange: #FF8A5A;
$body: #1D1D1F;
$secondary: #2C2733;
$danger: #cb0909;
$blue: #3EB8E5;
$blue2: #3AB9FF;
$blue3: #1871E3;
$blue4: #41B4E3;
$darkBlue: #3B4CEF;
$darkBlue2: #2136A6;
$midnight: #06063E;
$midnight2: #0F0A61;
$fuchsia: #BE33A1;
$white: #fff;
$muted: #888887;

$gradient-fuchsia: linear-gradient(180deg, $fuchsia 0%, $pink 100%);
$gradient-purple: linear-gradient(180deg, $purple3 0%, $purple2 100%);
$gradient-purple2: linear-gradient(141deg, $purple3 0%, $purple2 100%);
$gradient-purple3: linear-gradient(133deg, $purple3 0%, $purple2 100%);
$gradient-purple4: linear-gradient(115deg, $purple3 0%, $purple2 100%);
$gradient-purple6: linear-gradient(102.14deg, $purple3 36.98%, $pink 100%);  ;
$gradient-dark-blue: linear-gradient(115deg, $darkBlue 0%, $darkBlue2 100%);
$gradient-pink: linear-gradient(180deg, $pink 0%, $orange 50%, $yellow 100%);
$gradient-pink2: linear-gradient(140deg, $pink 0%, #FF7161 60%, #FF9257 80%, #FFAE45 100%);
$gradient-pink3: linear-gradient(163deg, $pink 20%, #FF7161 60%, #FF9257 80%, #FFAE45 100%);
$gradient-red: linear-gradient(180deg, $red 0%, #fe4d79 100%);
$gradient-blue: linear-gradient(180deg, $lavender 0%, $blue2 100%);
$gradient-blue2: linear-gradient(165deg, $lavender 0%, $blue2 100%);
$gradient-blue3: linear-gradient(157deg, $lavender 0%, $blue2 100%);
$gradient-teal: linear-gradient(180deg, $teal 0%, $blue 100%);
$gradient-teal2: linear-gradient(141deg, $teal 0%, $blue 100%);
$gradient-teal3: linear-gradient(115deg, $teal 0%, $blue 100%);
$gradient-teal4: linear-gradient(0deg, $teal 0%, $blue 100%);
$gradient-midnight: linear-gradient(75deg, $midnight 0%, $darkBlue2 100%);
$gradient-purple-picton-blue: linear-gradient(180deg, $purple6 0%, $picton-blue 100%);
$gradient-purple-picton-blue2: linear-gradient(146deg, $purple6 0%, $picton-blue 100%);
$gradient-purple-picton-blue3: linear-gradient(133deg, $purple6 0%, $picton-blue 100%);
$gradient-purple-picton-blue4: linear-gradient(342deg, #57F7E6 0%, $purple2 100%);
$gradient-purple-picton-blue5: linear-gradient(350deg, #57F7E6 0%, $purple2 100%);
$gradient-purple-blue: linear-gradient(141deg, $purple2 0%, $blue 100%);
$radial-gradient-purple: radial-gradient(closest-side, #F61882 0%, #F618834D 57%, #FF4F7B00 85%, #F6188300 100%);

// Then add them to your custom theme-colors map, together with any additional colors you might need
$theme-colors: (
        purple9: $purple9,
        primary: $primary,
        secondary: $secondary,
        success: $success,
        info: $info,
        warning: $warning,
        danger: $danger,
        light: $light,
        dark: $body,
        grey7: $grey7,
        grey2: $grey2,
        grey4: $grey4,
        grey5: $grey5,
        purple5: $purple5,

  // add any additional color below
);

$body-color: $black1;

$input-border-color: $grey5;

$grid-breakpoints: (
        s: 0,
        xs: 450px,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1480px,
        xxxl: 1680px,
        xxxxl: 1920px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1400px
);

$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        '2-half': $spacer * .75,
        3: $spacer,
        '3-half': $spacer * 1.25,
        4: $spacer * 1.5,    
        5: $spacer * 2,
        '5-half': $spacer * 2.25,  
        6: $spacer * 2.5,
        7: $spacer * 3,
        8: $spacer * 3.5,
        9: $spacer * 4,
        10: $spacer * 4.5,
        11: $spacer * 5,
        12: $spacer * 5.5,
        13: $spacer * 6,
        14: $spacer * 6.5,
        15: $spacer * 7,
        16: $spacer * 7.5,
        17: $spacer * 8,
        18: $spacer * 8.5,
        19: $spacer * 9,
        20: $spacer * 9.5,
        21: $spacer * 10,
        22: $spacer * 10.5,
        23: $spacer * 11,
        24: $spacer * 11.5, 
        25: $spacer * 12,
        26: $spacer * 12.5,
        27: $spacer * 13,
        28: $spacer * 13.5,
        29: $spacer * 14,
        30: $spacer * 15,
        31: $spacer * 15.5,
        32: $spacer * 16,  
        33: $spacer * 16.5,
        34: $spacer * 17,
        35: $spacer * 17.5,
        36: $spacer * 18,
        37: $spacer * 18.5,
        38: $spacer * 19,
        39: $spacer * 19.5,
        40: $spacer * 20, 
        41: $spacer * 20.5,
        42: $spacer * 21,
        43: $spacer * 21.5,
        44: $spacer * 22,
        45: $spacer * 22.5,
        46: $spacer * 22.5,
        47: $spacer * 23,
        48: $spacer * 23.5,
        49: $spacer * 24,
        50: $spacer * 24.5,
        51: $spacer * 25, 
        52: $spacer * 25.5,
        53: $spacer * 26,
        54: $spacer * 26.5,
        55: $spacer * 27,
        56: $spacer * 27.5,
        57: $spacer * 28,
        58: $spacer * 28.5,
        59: $spacer * 29,
        60: $spacer * 29.5,
        61: $spacer * 30,
        62: $spacer * 30.5,
        63: $spacer * 31,
        64: $spacer * 31.5,
        65: $spacer * 32,
        66: $spacer * 32.5,
        67: $spacer * 33, 
        68: $spacer * 33.5,
);

/*! purgecss start ignore */
$colors: (
        black1: $black1,
        black2: $black2,
        blue2: $blue2,
        blue3: $blue3,
        blue4: $blue4,
        blue: $blue,
        danger: $danger,
        gradient-blue: $gradient-blue,
        gradient-blue2: $gradient-blue2,
        gradient-blue3: $gradient-blue3,
        gradient-dark-blue: $gradient-dark-blue,
        gradient-fuchsia: $gradient-fuchsia,
        gradient-midnight: $gradient-midnight,
        gradient-pink: $gradient-pink,
        gradient-pink2: $gradient-pink2,
        gradient-pink3: $gradient-pink3,
        gradient-red: $gradient-red,
        gradient-purple: $gradient-purple,
        gradient-purple2: $gradient-purple2,
        gradient-purple3: $gradient-purple3,
        gradient-purple4: $gradient-purple4,
        gradient-purple6: $gradient-purple6,
        gradient-teal: $gradient-teal,
        gradient-teal2: $gradient-teal2,
        gradient-teal3: $gradient-teal3,
        gradient-teal4: $gradient-teal4,
        gradient-purple-picton-blue: $gradient-purple-picton-blue,
        gradient-purple-picton-blue2: $gradient-purple-picton-blue2,
        gradient-purple-picton-blue3: $gradient-purple-picton-blue3,
        gradient-purple-picton-blue4: $gradient-purple-picton-blue4,
        gradient-purple-picton-blue5: $gradient-purple-picton-blue5,
        gradient-purple-blue: $gradient-purple-blue,
        radial-gradient-purple: $radial-gradient-purple,
        grey1: $grey1,
        grey2: $grey2,
        grey3: $grey3,
        grey4: $grey4,
        grey5: $grey5,
        grey6: $grey6,
        grey7: $grey7,
        grey8: $grey8,
        lavender: $lavender,
        midnight2: $midnight2,
        muted: $muted,
        pink2: $pink2,
        pink3: $pink3,
        pink4: $pink4,
        pink5: $pink5,
        pink: $pink,
        primary: $primary,
        purple1: $purple1,
        purple2: $purple2,
        purple3: $purple3,
        purple4: $purple4,
        purple5: $purple5,
        purple7: $purple7,
        purple8: $purple8,
        cerise: $cerise,
        red: $red,
        red2: $red2,
        secondary: $secondary,
        teal: $teal,
        pastel-green: $pastel-green,
        white: $white,
        yellow: $yellow,
        yellow2: $yellow2,
        orange: $orange,
);

$font-weight-semibold: 600;
$font-weight-bold: 700;

@each $color in map-keys($colors) {
  .bg-#{"" +$color} {
    background: map-get($colors, $color);
  }
  .color-#{"" +$color} {
    color: map-get($colors, $color);
  }
}
/*! purgecss end ignore */
