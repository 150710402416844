.grid-container {
  column-gap: 30px;
}

.grid-item {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 30px;
  break-inside: avoid;
}

@include media-breakpoint-up(md) {
  .grid-container {
    column-count: 3;
  }
}

@include media-breakpoint-between(sm, md) {
  .grid-container {
    column-count: 2;
  }
}

.vh-100 {
  height: 100vh;
}

.w-50 {
  width: 50%;
}

.flex-shrink-0 {
  flex-shrink: 0;
}
.trusted-partners-gap {
  row-gap: 40px;
  column-gap: 80px;
}

@include media-breakpoint-down(lg) {
  .trusted-partners-gap {
    row-gap: 60px;
    column-gap: 38px;
  }
}
