.hero {
  position: relative;
  &__heading {
    letter-spacing: -3.2px;
  }
  &__sub-heading {
    font-weight: 400;
    letter-spacing: -1.05px;
  }
  &__image {
    height: auto;
  }
  &--home {
    .hero__image {
      position: absolute;
      bottom: -5em;
      z-index: 0;
    }
  }
  &--default {
    min-height: 599px;
    .hero__image {
      position: absolute;
    }
  }
  &--full-screen {
    background-repeat: no-repeat;
    background-size: cover;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: transparent linear-gradient(180deg, #1D1D1F 0%, #1D1D1F00 100%);
    }
  }
  &--konnect {
    background-image: url('/images/konnect/hero_konnect_bg.png');
    background-repeat: no-repeat;
    .hero {
      &__image {
        display: block;
      }
    }
  }
}
/*! purgecss start ignore */
.hero {
  &--plan {
    min-height: 516px;
    margin-top: 50px;
    .hero__image {
      position: absolute;
      right: 0;
    }
    .h3 {
      font-size: 34px;
      letter-spacing: -1.7px;
      line-height: 41px;
    }
    .reviews {
      position: absolute;
      bottom: 0;
    }
  }
  &--full-screen {
    &.hero-full-screen {
      &--option1 {
        &:before {
          opacity: 0.8;
        }
      }
      &--option2 {
        &:before {
          opacity: 1;
          height: 300px;
        }
      }
      &--option3 {
        &:before {
          opacity: 0.8;
          height: 300px;
        }
      }
    }
  }
}
/*! purgecss end ignore */

@include media-breakpoint-up(xxl) {
  .hero {
    &--full-screen {
      h1 {
        line-height: 68px;
        font-size: 64px;
        letter-spacing: -3.2px;
      }
    }
  }
}

@include media-breakpoint-up(xxxl) {
  /*! purgecss start ignore */
  .hero {
    &--plan {
      .hero__image {
        bottom: -10.5vw;
        max-width: 65%;
        &--collect-plan {
          max-width: 86%;
        }

      }
    }
  }
  /*! purgecss end ignore */
}

@include media-breakpoint-down(xxxxl) {
  /*! purgecss start ignore */
  .hero {
    &--plan {
      .hero__image {
        bottom: -8.5vw;
        max-width: 75%;
        &--collect-plan {
          max-width: 86%;
        }
        &--phone-plan,
        &--forward-plan,
        &--scanPro-plan {
          max-width: 550px;
        }

      }
    }
  }
  /*! purgecss end ignore */
}

@include media-breakpoint-down(xxxl) {
  /*! purgecss start ignore */
  .hero {
    &--plan {
      min-height: 375px;
      margin-top: 12px;
      .hero__image {
        bottom: -100px;
        max-width: 50%;
        height: auto;
        &--collect-plan {
          max-width: 600px;
        }
        &--scanLite-plan {
          max-width: 550px;
        }
        &--phone-plan {
          max-width: 400px;
        }

      }
    }
  }
  /*! purgecss end ignore */
}

@include media-breakpoint-up(xl) {
  .hero {
    &--full-screen {
      h1 {
        font-size: 80px;
        line-height: 1;
        letter-spacing: -5.1px;
      }
      h2 {
        font-size: 38px;
        line-height: 1;
        letter-spacing: -1.4px;
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .hero {
    &--full-screen {
      h1 {
        font-size: 92px;
        line-height: 1;
        letter-spacing: -5.1px;
      }
      h2 {
        font-size: 44px;
        line-height: 1;
        letter-spacing: -2.7px;
      }
    }
  }
}

@include media-breakpoint-up(xxxl) {
  .hero {
    &--powered-by {
      height: 599px;
    }
    &--home {
      padding-top: 4.8rem;
      padding-bottom: 4.4rem;
      .hero__image {
        width: 480px;
      }
    }
    &--default {
      .hero__image {
        max-width: 52vw;
        height: auto;
        margin-bottom: -28em;
      }
    }
    &--full-screen {
      h1 {
        font-size: 102px;
        line-height: 1;
        letter-spacing: -5.1px;
        color: #FFFFFF;
        text-shadow: 0 3px 6px #00000029;
      }
      h2 {
        font-size: 54px;
        line-height: 1;
        letter-spacing: -2.7px;
        color: #FFFFFF;
        text-shadow: 0 3px 6px #00000029;
      }
    }
  }
}

@include media-breakpoint-down(xxxl) {
  .hero {
    &--powered-by {
      height: 510px;
    }
    &--home {
      padding-top: 3.8rem;
      padding-bottom: 3.5rem;
      .hero__image {
        width: 420px;
      }
    }
    &--default {
      min-height: 500px;
      .hero__image {
        max-width: 40vw;
        height: auto;
        margin-bottom: -20em;
        &--large {
          max-width: 50vw;
        }
        &--medium {
          max-width: 45vw;
        }
        &--high-height {
          height: 110%;
          width: auto;
          max-width: initial;
        }
      }
    }
    &--plan {
      .h3 {
        font-size: 28px;
        line-height: 35px;
      }
    }
  }
}

@include media-breakpoint-down(xxl) {
  .hero {
    &--powered-by {
      height: 460px;
    }
    &--home {
      .hero__image {
        width: 385px;
      }
    }
  }
  /*! purgecss start ignore */
  .hero {
    &--plan {
      .hero__image {
        &--collect-plan {
          width: 500px;
        }
        &--forward-plan {
          width: 375px;
        }
        &--scanLite-plan {
          width: 475px;
        }
        &--scanPro-plan {
          width: 370px;
        }
        &--phone-plan {
          width: 375px;
        }

      }
    }
  }
  /*! purgecss end ignore */
}

@media (max-width: 1199.98px), (max-height: 720px) and (max-width: 1280px) {
  /*! purgecss start ignore */
  .hero {
    &--plan {
      min-height: 344px;
      border-radius: 12px;
      .hero__image {
        height: auto;
        max-height: initial;
        right: -12px;
        &--collect-plan {
          width: 299px;
          bottom: -55px !important;
        }
        &--forward-plan {
          bottom: -139px !important;
          width: 276px;
        }
        &--scanLite-plan {
          bottom: -80px !important;
          width: 282px;
        }
        &--scanPro-plan {
          bottom: -60px !important;
          width: 202px;
        }
        &--phone-plan {
          bottom: -115px !important;
          width: 246px;
          right: 0;
        }

      }
    }
  }
  /*! purgecss end ignore */
}

@include media-breakpoint-between(lg, xl) {
  .hero {
    &--powered-by {
      height: 330px;
    }
    &--home {
      .hero__image {
        width: 350px;
        right: 0;
      }
    }
    &--default {
      .hero__image {
        max-width: 500px;
        margin-bottom: -19em;
        right: 0;
        &--large {
          max-width: 580px;
          margin-bottom: -25em;
        }
      }
    }
    &--plan {
      .hero__image {
        bottom: -100px;
        max-width: 400px;
        height: auto;
        right: 10vw;

      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .hero {
    &--full-screen {
      height: 100vh;
      background-position: center;
      margin-top: -64px;
    }
  }
}

@include media-breakpoint-down(xl) {
  .hero {
    &--full-screen {
      height: 550px;
      &:before {
        transform: matrix(-1, 0, 0, -1, 0, 0);
        background: transparent linear-gradient(0deg, #222222 0%, #22222200 100%);
        opacity: 0.85;
        height: 50%;
      }
    }
  }
  /*! purgecss start ignore */
  .hero {
    &--plan {
      .hero__image {
        max-width: initial;
      }
    }
    &--collect-plan {
      margin-bottom: 12px;
    }
    &--forward-plan {
      margin-bottom: 92px;
    }
    &--scanLite-plan {
      margin-bottom: 10px;
    }
    &--scanPro-plan {
      margin-bottom: 20px;
    }
    &--phone-plan {
      margin-bottom: 50px;
    }
  }
  /*! purgecss end ignore */
  .hero {
    &--default {
      .hero__image {
        &--high-height {
          height: 100%;
          width: auto;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .hero {
    &--powered-by {
      .hero__image {
        &--mailroom {
          width: 60vw;
        }
      }
      .hero-brand {
        &--mailroom {
          width: 36vw;
        }
      }
    }
    &--konnect {
      background-image: url('/images/konnect/ellipse-desktop.svg'), url('/images/konnect/konnect-hero-arrow.png'), url('/images/konnect/konnect-hero-bg.png');
      background-position: 50% 131px, calc(50% + 85px) 46px, calc(50% + 105px) 30px;
      .hero {
        &__image {
          margin-top: -10px;
          margin-left: auto;
          margin-right: 20%;
          width: 34%;
        }
        &__logos {
          top: 23%;
          left: 20%;
          width: 34%;
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .hero {
      &--powered-by {
      &.hero--mailroom {
        height: 307px;
      }
      .hero-brand {
        width: 302px;
      }
      .hero__image {
        max-width: initial;
        &--mailroom {
          width: 465px;
        }
      }
      .hero-brand {
        &--mailroom {
          width: 312px;
        }
      }
    }
    &--home {
      .hero__image {
        width: 280px;
        right: 0;
      }
    }
    &--default {
      padding-top: 3em;
      min-height: initial;
      height: 462px;
      margin-bottom: 10em;
      &.hero {
        &--mb-mobile {
          &-extra-small {
            margin-bottom: 5em;
          }
          &-small {
            margin-bottom: 8em;
          }
          &-medium {
            margin-bottom: 11em;
            &-2 {
              margin-bottom: 11.5em;
            }
          }
        }
      }
      .hero__image {
        max-width: 375px;
        position: initial;
        margin-bottom: 0;
        &--high-height {
          height: 417px;
          width: auto;
        }
      }
    }
    &--konnect {
      background-image: none;
      .hero {
        &-brand {
          width: 317px;
        }
        &-powered-by-logo {
          svg {
            width: 125px;
          }
        }
        &__image {
          max-width: 375px;
        }
      }
    }
    &--mobile-initial-height {
      height: initial !important;
      margin-bottom: 0 !important;
    }
  }
}

@include media-breakpoint-up(md) {
  .hero {
    &__heading {
      font-size: 64px;
      line-height: 70px;
    }
    &__sub-heading {
      font-size: 21px;
      line-height: 29px;
    }
  }
}

@include media-breakpoint-down(md) {
  .hero {
    &--full-screen {
      margin-top: -43px;
      height: 500px;
      background-position: top 30px right -113px;
      &.hero--business-support {
        background-position: top 30px right -280px;
      }
      &.hero--outreach {
        background-position: top 30px right -200px;
      }
    }
    &__heading {
      font-size: 40px;
    }
    &__sub-heading {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.45px;
      padding: 0 25px;
    }
    &--home {
      padding-top: 2.4rem;
      padding-bottom: 8em;
      margin-bottom: 12em;

      .hero__image {
        width: 290px;
        bottom: -165px;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .hero {
    &--default {
      padding-top: 2.3em;
      height: 462px;
      &.hero--mobile-small {
        height: 444px;
      }
    }
    .h1 {
      letter-spacing: -2px;
    }
    &--plan {
      min-height: 244px;
      .h3 {
        font-size: 16px;
        line-height: 21px;
        letter-spacing: -0.4px;
      }
    }
  }
}
