.list-footer {
  margin-bottom: 1rem;
  li {
    font-size: 13px;
    padding-top: .75em;
  }
  a {
    font-size: 13px;
    padding-top: 0.75em;
    text-decoration: none;
    color: #333;
    letter-spacing: -0.33px;
  }
}

.mp-list-footer {
  margin-bottom: 1rem;
  li {
    font-size: 13px;
    padding-top: .75em;
  }
  a {
    font-size: 13px;
    padding-top: 0.75em;
    text-decoration: none;
    color: $white;
    letter-spacing: -0.33px;
  }
}

.list-border-right {
  border-right: 1px solid $black1;
  padding: 0 15px;
}

@include media-breakpoint-down(md) {
  .list-border-right {
    margin-right: 0.75em;
    padding-right: 0.75em;
  }
}
