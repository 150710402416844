.gallery {
  overflow: hidden;
  div {
    position: relative;
  }
  img {
    box-shadow: 0 20px 20px #00000033;
    border-radius: 25px;
  }
}

@include media-breakpoint-down(sm) {
  .gallery {
    img {
      border-radius: 15px;
    }
  }
}
