.nav-logo {
  cursor: pointer;
  &:hover {
    path {
      fill: $yellow;
    }
    #circle {
      stroke: $yellow;
    }
  }
}

.mobile-nav-container {
  padding-left: 20px;
  padding-right: 20px;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.nav-item {
  padding: .95em 0 .3em 0;
  cursor: pointer;
  &--bordered {
    >div {
      padding-bottom: 2px;
      border-bottom: 4px solid transparent;
    }
    &:hover {
      >div {
        border-color: $pink;
      }
    }
  }
  &:hover {
    .nav-link-title {
      color: $yellow;
    }
  }
}

nav {
  background-color: #1D1D1F;
  .nav-link {
    padding: 10px 0 10px 0;
    line-height: 22px;
    &-title {
      color: white;
      height: 44px;
      letter-spacing: -0.05px;
      justify-content: center;
      flex-direction: column;
      &.active {
        color: $yellow;
      }
    }
  }
}

.navbar {
  padding-top: 0;
  padding-bottom: 0;
  z-index: 2;
  flex-wrap: nowrap;
  justify-content: flex-start;
  .collapsible {
    &__header {
      margin-top: 0.4em;
      margin-bottom: 0.4em;
    }
  }
}

.dropdown-hover {
  .dropdown-hover-show {
    position: absolute;
    cursor: default;
    top: 37px;
    left: -55px;
    z-index: 2;
    pointer-events: none;
    opacity: 0;
    box-shadow: 0px 3px 6px #00000029;
    @include transition(opacity 300ms);
    .dropdown-hover-content {
      background-color: white;
      letter-spacing: -0.8px;
      font-size: 16px;
      .dropdown-title {
        line-height: 22px;
        display: block;
        padding-bottom: 10px;
        padding-left: 0;
      }
      .row {
        flex-wrap: nowrap;
        width: fit-content;
        padding: 35px 0;
      }
      .nav-group {
        width: auto;
        padding: 0px 52px;
        &:first-child {
          border-right: 1px solid $grey2;
        }
      }
      &.dropdown-hover-content__single-item {
        .nav-group {
          &:first-child {
            border-right: none;
          }
        }
      }
      .nav-link {
        span {
          color: $black1;
          &.active {
            color: $pink;
            font-weight: $font-weight-semibold;
          }
        }

      }
    }
    &.in {
      opacity: 1;
      pointer-events: auto;
    }
  }
  position: relative;
}

.badge {
  width: 13px;
  height: 13px;
  font-size: 10px;
  color: $black1;
  background-color: $white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: inherit;
}

@include media-breakpoint-up(xl) {
  .nav-item {
    padding: 0.95em 1.6em 0.45em 1.6em;
  }
}

@include media-breakpoint-up(xxl) {
  .nav-item {
    padding: 0.95em 2.6em 0.45em 2.6em;
  }
}

@include media-breakpoint-down(xl) {
  .navbar {
    padding: 0.15em 0;
  }
  .nav-item {
    margin-right: 1em;
    padding: 0.5em 0 0.3em 0;
  }
  .mobile-nav-items {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .navbar > .container {
    display: block;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .mobile-menu-container {
    padding-left: 20px;
    padding-right: 20px;
    .collapsible {
      border-bottom: 1px solid rgba(255,255,255,0.3);
      &__header {
        padding-top: 0.75em;
        padding-bottom: .75em;
        user-select: none;
        .lead-large {
          font-size: 18px;
          font-weight: $font-weight-bold !important;
        }
      }
      .accordion-items-container {
        padding-bottom: 1em;
        div {
          padding: .25em 0;
        }
        a {
          text-decoration: none;
          color: $white;
          font-size: 16px;
        }
      }
    }
    .h6 {
      font-size: 18px;
    }
    .text-small {
      font-size: 16px;
      letter-spacing: -0.4px;
    }
    .collapsible {
      &__header {
        height: auto;
        min-height: initial;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .navbar {
    .list-border-right {
      margin-right: 0.5em;
      padding-right: 0.5em;
    }
    .accordion-items-container {
      .link {
        font-size: 16px;
        line-height: 34px;
        letter-spacing: -0.4px;
      }
    }
  }
}

@media (max-width: 376px) {
  .navbar {
    .list-unstyled {
      li:nth-child(2) {
        border-right: none;
      }
    }
  }
}

.pink-page {
  background-color: $pink;
}
