.btn {
  font-size: 17px;
  letter-spacing: -.85px;

  &--inactive {
    opacity: 0.25;
  }

  &--square {
    border-radius: 6px;
  }
}

.btn-secondary {
  background-color: transparent;
  color: $black1;

  &:hover {
    background-color: $black1;
  }
}

.btn-black {
  background-color: $black1;
  color: $white;

  &:hover {
    background-color: $black1;
    color: $white;
  }

  &:disabled {
    border-width: 0;
    background-color: $grey2 !important;
    color: $white !important;
    opacity: 1;

    &:hover {
      background-color: $grey2;
    }
  }
}

.btn-tertiary {
  background-color: $pink;
  border-color: $pink;
  border-width: 1px;
  height:51px;
  &:hover {
    background-color: $pink ;
    border-color: $primary;
    border-width: 1px;
  }
}

.btn-cerise {
  background-color: $purple2;
  border-color: $purple2;
  border-width: 1px;
  color: white;
  &:hover,
  &:active {
    background-color: $purple2;
    border-color: $purple2;
    color: white;
  }
}

.btn-light {
  background-color: $white;
  border-color: $white;
  border-width: 1px;
  color: $black;
  box-shadow: 0 0 12px 0 #00000040;
  &:hover,
  &:active {
    background-color: $white;
    border-color: $white;
  }
}

.btn-extra-lg {
  font-size: 21px;
  letter-spacing: -0.2px;
  height:51px;
  @include button-size($btn-padding-y, $btn-padding-x-extra-lg, $btn-font-size, $btn-border-radius-extra-lg);
}

.btn-lg {
  font-size: 21px;
}

.btn-md {
  @include button-size($btn-padding-y, $btn-padding-x-md, $btn-font-size, $btn-border-radius-md);
}

.btn-sm {
  font-size: 14px;
  letter-spacing: -0.35px;
}
.btn-xs {
  font-size: 12px;
  letter-spacing: -0.48px;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 24px;
}

.btn-link {
  background-color: transparent;
  color: $primary;
  text-decoration: none;

  &:hover {
    color: darken($primary, 5);
  }
}

.btn-dark {
  background-color: $black;
  color: $white;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;

  &:disabled {
    opacity: 0.3;
  }

  &:hover {
    color: darken($white, 5);
  }
}

.btn-outline-white {
  --bs-btn-color: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: 0, 113, 227;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #fff;
  --bs-btn-active-border-color: #fff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fff;
  --bs-gradient: none;

  &:hover {
    color: #000;
    background-color: #fff;
    border-color: #fff
  }
}

.btn-icon {
  padding: 8px 8px;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    background-color: transparent;
  }

  @include media-breakpoint-down(lg) {
    &--mobile-small {
      width: 34px;
      height: 34px;
    }
  }
}

.btn-pagination {
  background-color: #cccccc;
  padding: 3px;

  &:hover {
    background-color: darken($grey1, 10);
  }

  &:disabled {
    background-color: #888888;
  }

  &:active {
    background-color: #cccccc !important;
  }
}

@include media-breakpoint-down(lg) {
  .btn-extra-lg {
    font-size: 17px;
    line-height: 16px;
    letter-spacing: 0;
    height: 38px;
    width: 295px;
  }
}

@include media-breakpoint-down(md) {
  .btn {
    --bs-btn-padding-x: 2.35em;
    --bs-btn-padding-y: 0.25em;
  }
}

@include media-breakpoint-down(sm) {
  .btn {
    --bs-btn-padding-x: 1.5em;
    --bs-btn-padding-y: 0.25em;
  }
}

.rounded-lg {
  border-radius: 22px;
}
