.thankyou {
  &-container {
    height: 605px;
  }
  &-cards {
    margin-top: -335px;
  }
  &-card {
    box-shadow: 0 6px 6px #0000001a;
    .lead-large {
      line-height: 37px;
    }
    .text-large {
      font-size: 18px;
      line-height: 33px;
      letter-spacing: -0.45px;
    }
    .text-small-m {
      line-height: 21px;
    }
    .h4 {
      font-size: 38px;
      line-height: 46px;
      letter-spacing: -1.9px;
    }
  }
  &-contact-container {
    border-bottom: 1px solid #D2D2D6;
    padding: 12px 0;
  }
  &-image {
    position: absolute;
    right: 0;
    top: -65px;
    width: auto;
    height: 110%;
  }
}

@include media-breakpoint-down(xxxl) {
  .thankyou {
    &-cards {
      margin-top: -350px;
    }
    &-image {
      top: -15px;
      width: auto;
      height: 90%;
    }
  }
}

@include media-breakpoint-down(xxl) {
  .thankyou {
    &-image {
      top: 20px;
      width: auto;
      height: 75%;
    }
  }
}

@include media-breakpoint-down(xl) {
  .thankyou {
    &-card {
      .h4 {
        font-size: 28px;
        letter-spacing: -1.4px;
        line-height: 32px;
      }
    }
    &-image {
      top: 80px;
      width: auto;
      height: 55%;
    }
  }
}

@include media-breakpoint-down(lg) {
  .thankyou {
    &-cards {
      margin-top: -200px;
    }
    &-card {
      .lead-large {
        letter-spacing: -1.05px;
      }
      .text-large {
        line-height: 24px;
      }
      .headline {
        font-size: 16px;
        letter-spacing: -0.4px;
      }
    }
    &-contact-container {
      padding: 22px 0 16px;
    }
    &-container {
      height: 449px;
    }
    &-image {
      display: none;
    }
  }
}

@include media-breakpoint-down(sm) {
  .thankyou {
    &-cards {
      margin-top: -180px;
    }
    &-card {
      .lead-extrasmall-mobile {
        line-height: 28px;
      }
    }
  }
}
