$header-margin: 60px;
$content-margin: 100px;
.become-a-partner-form {
  margin-top: - calc($content-margin + $header-margin);
  margin-bottom: calc($content-margin + $header-margin);
}

@include media-breakpoint-down(lg) {
  .become-a-partner-form {
    margin-top: -$header-margin;
    margin-bottom: $header-margin;
  }
}
