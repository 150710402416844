.modal {
  &-blog-search {
    position: absolute;
    padding-top: 52.4px;
    .modal-content {
      border-radius: 0;
      padding: 0;
      background: transparent;
    }
    .modal-dialog {
      max-width: 100%;
      margin: 0;
    }
    .modal-body {
      padding: 0;
    }
  }
  &-content {
    padding: 20px 20px 62px;
    border-radius: 22px;
    position: relative;
    max-height: 100vh;
  }
  &-text {
    padding: 20px 20px 0;
    font-weight: normal;
  }
  &-btn {
    position: absolute;
    bottom: 65px;
  }
}

/*! purgecss start ignore */
.modal {
  &-image1 {
    position: absolute;
    height: 100%;
    width: auto;
    right: 0;
    top: 0;
    bottom: 0;
  }
  &-image2 {
    position: absolute;
    height: 85%;
    width: auto;
    right: 50px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  &-image3 {
    position: absolute;
    height: 75%;
    width: auto;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto 0 15px;
  }
}
/*! purgecss end ignore */

@include media-breakpoint-up(xxxl) {
  .modal {
    &-lg {
      .modal-content {
        height: 660px;
      }
    }
  }
}

@include media-breakpoint-down(xxxl) {
  .modal {
    &-lg {
      .modal-content {
        height: 600px;
      }
    }
  }
}

@include media-breakpoint-down(xxl) {
  .modal {
    &-lg {
      --bs-modal-width: 747px;
    }
    &-image1,
    &-image2 {
      position: initial;
      width: 100%;
      height: auto;
    }
    &-btn {
      position: initial;
    }
    &__text {
      padding: 10px 20px 0;
    }
  }
  /*! purgecss start ignore */
  .modal {
    &-image1,
    &-image2 {
      position: initial;
      width: 100%;
      height: auto;
    }
  }
  /*! purgecss end ignore */
}

@include media-breakpoint-down(xl) {
  /*! purgecss start ignore */
  .modal {
    &-lg {
      .modal-content {
        height: 460px;
      }
    }
    &-image3 {
      bottom: -100px;
    }
  }
  /*! purgecss end ignore */
}

@include media-breakpoint-down(lg) {
  .modal {
    &-lg {
      .modal-content {
        height: 800px;
      }
    }
  }
  /*! purgecss start ignore */
  .modal {
    &-image1,
    &-image2,
    &-image3 {
      width: 50%;
      margin: auto;
    }
    &-image3 {
      position: initial;
      width: 100%;
      height: auto;
    }
  }
  /*! purgecss end ignore */
}

@include media-breakpoint-down(sm) {
  .modal {
    &-lg {
      .modal-content {
        height: 90vh;
      }
    }
    &-body {
      padding: 5px;
    }
    &-content {
      padding: 20px 24px 40px;
      h5 {
        letter-spacing: -1.05px;
      }
      .text-medium {
        letter-spacing: -0.8px;
      }
    }
    &-text {
      padding: 20px 0 0;
      font-weight: normal;
    }
    &-btn {
      width: 252px;
      margin: 26px auto 0;
      display: inherit;
    }
  }
  /*! purgecss start ignore */
  .modal {
    &-image1,
    &-image2,
    &-image3 {
      width: 100%;
    }
  }
  /*! purgecss end ignore */
}
