@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
  url(/fonts/open-sans-v17-latin-regular.woff2) format("woff2"),
  url(/dist/public/fonts/open-sans-v17-latin-regular.woff) format("woff");
  font-display: swap;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
  url(/fonts/open-sans-v17-latin-600.woff2) format("woff2"),
  url(/dist/public/fonts/open-sans-v17-latin-600.woff) format("woff");
  font-display: swap;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"), local("OpenSans-Bold"),
  url(/fonts/open-sans-v17-latin-700.woff2) format("woff2"),
  url(/dist/public/fonts/open-sans-v17-latin-700.woff) format("woff");
  font-display: swap;
}

body {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
}

.display-1 {
  font-size: 80px;
  line-height: 84px;
  letter-spacing: -4px;

  &--lh-sm {
    line-height: 72px !important;
  }
}

.display-2 {
  font-size: 44px;
  line-height: 52px;
  letter-spacing: -2.2px;
}

.display-3 {
  font-size: 34px;
  line-height: 42px;
  letter-spacing: -1.7px;
}

.display-4 {
  font-size: 32px;
  line-height: 1.625;
  letter-spacing: -0.2px;
}

.pioneering-virtual-office-solutions-since-2010-card {
  .display-4,
  .lead-large {
    line-height: 38px;
    letter-spacing: -1px;
  }

  @include media-breakpoint-down(xxxl) {
    .display-4,
    .lead-large {
      line-height: 28px;
    }
  }
  @include media-breakpoint-down(xxl) {
    .display-4,
    .lead-large {
      line-height: 24px;
    }
  }
  @include media-breakpoint-down(xl) {
    .display-4,
    .lead-large {
      font-size: 21px;
      line-height: 26px;
    }
  }
}

.quote {
  font-size: 32px;
  line-height: 1.5;
  letter-spacing: -1.65px;
}

.display-5 {
  font-size: 36px;
  line-height: 1.167;
  letter-spacing: -1.6px;

  &--lh2 {
    line-height: 1.5;
  }
}

.display-mega {
  font-size: 200px;
  font-weight: bold;
  letter-spacing: -19.7px;
  line-height: 180px;
}

.h1,
h1 {
  line-height: 76px;
  font-size: 64px;
  letter-spacing: -3.2px;

  &--lh2 {
    line-height: 84px;
  }
}

.h2,
h2,
.h2-static {
  font-size: 48px;
  letter-spacing: -2.4px;
  line-height: 52px;
}

.hero--konnect {
  .lead-large-desktop,
  .h2,
  h2 {
    letter-spacing: -1.6px;
  }
}

.h3,
h3 {
  font-size: 40px;
  letter-spacing: -2px;
}

.h4,
h4 {
  font-size: 28px;
  letter-spacing: -1.4px;

  &--ls {
    line-height: 44px;
  }
}

.h5,
h5 {
  font-size: 24px;
  letter-spacing: -1.2px;
}

.h6,
h6 {
  font-size: 21px;
  letter-spacing: -1.2px;
}

.lead {
  font-size: 21px;
  line-height: 29px;
  letter-spacing: -1.05px;
  font-weight: normal;

  &-large,
  &-large-desktop {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -1.2px;

    &--ls2 {
      letter-spacing: -1.6px;
    }

    &--lh2 {
      line-height: 32px;
    }
  }

  &-large-mobile {
    font-size: 21px;
    line-height: 29px;
    letter-spacing: -1.05px;
    font-weight: normal;
  }
}

.per-month-text {
  letter-spacing: -0.85px;
}

.line-height-large {
  p {
    margin-bottom: 28px;
    letter-spacing: -1.2px;
  }
}

//html, body, h1,h2,h3,h4,h5 {
//  color: $body;
//}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

/*! purgecss start ignore */
.text-gradient-purple {
  background: -webkit-linear-gradient(0deg, $purple3 0%, #BC2CC0 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-pink {
  background: -webkit-linear-gradient(0deg, #FF5179 0%, #FFCC34 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-blue {
  background: -webkit-linear-gradient(0deg, #6C6ED4 0%, $blue 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-purple-picton-blue {
  background: -webkit-linear-gradient(0deg, $purple2 0%, $blue 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-purple3-pink {
  background: -webkit-linear-gradient(270deg, $purple3 0%, $pink 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-red {
  background: -webkit-linear-gradient(90deg, #FD4D78 0%, #D5161A 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-red2 {
  background: -webkit-linear-gradient(0deg, #D5161A 0%, #FD4D78 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*! purgecss end ignore */

em {
  font-style: normal;
  color: $pink;
}

.text-semibold {
  font-weight: $font-weight-semibold !important;
}

.text-bold {
  font-weight: $font-weight-bold !important;
}

.text-normal {
  font-weight: $font-weight-normal !important;
}

.plan-text {
  font-weight: $font-weight-semibold;
  font-size: 21px;
  letter-spacing: -1.05px;
  display: block;
}

.text-xs {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.3px;
}

.text-small {
  font-size: 13px;
  letter-spacing: -0.33px;
}

.text-small-m {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.35px;
}

.text-medium {
  font-size: 17px;
  line-height: 25px;

  &2 {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.5px;
  }

  &3 {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.5px;
  }

  &--lt1 {
    letter-spacing: -0.85px;
  }

  &--lt2 {
    letter-spacing: -0.42px;
  }

  &--lt3 {
    letter-spacing: -1.6px;
  }
}

.text-large {
  font-size: 19px;
  letter-spacing: -0.95px;

  &-2 {
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.5px;
  }
}

.text-xl {
  font-size: 21px;
  line-height: 26px;
  letter-spacing: -1.05px;

  &-2 {
    font-size: 22px;
    letter-spacing: -1.1px;
  }

  &--ls2 {
    letter-spacing: -1.6px;
  }
}

.text-xxl {
  font-size: 24px;
  line-height: 26px;
  letter-spacing: -1.2px;

  &--lh2 {
    line-height: 32px;
  }
}

.text-xxxl {
  font-size: 28px;
  line-height: 26px;
  letter-spacing: -1.4px;
}

.text-underline {
  text-decoration: underline !important;
}

dd {
  letter-spacing: -0.42px;
}

a {
  &.text-link {
    &.color-red {
      &:hover {
        color: darken($red, 10);
      }
    }

    &.color-blue {
      &:hover {
        color: darken($blue, 10);
      }
    }
  }
}

.text-link {
  display: inline-block;
  cursor: pointer;
  text-decoration: none;

  &.text-small {
    line-height: 44px; // needed for accessibility
  }
}

.text-link-white {
  font-size: 18px;
}

.text-link-pink {
  color: $pink3;
  text-decoration: none;

  &:hover {
    color: darken($pink3, 10);
  }
}

.text-link-pink2 {
  color: $pink2;
  text-decoration: none;

  &:hover {
    color: darken($pink2, 10);
  }
}

.no-setup-text {
  text-align: center;
}

/*! purgecss start ignore */
.ten-minutes-text {
  font-size: 550px;
  line-height: 0.7;
  letter-spacing: -55px;

  &--option1 {
    margin-left: -90px;
  }

  &--option2 {
    margin-left: -140px;
  }
}

.mail-handling-slogan,
.mail-forwarding-slogan,
.same-day-scan-slogan,
.same-day-scan-slogan2,
.prime-london-business-address-slogan,
.coworking-text,
.evolving-platform-text,
.go-paperless-text,
.search-post-text {
  font-weight: bold;

  span {
    display: block;
  }
}

.mail-handling-slogan {
  font-size: 117px;
  line-height: 120px;
  letter-spacing: -5.85px;

  span {
    &:first-child {
      font-size: 241px;
      line-height: 210px;
      letter-spacing: -12.05px;
      margin-left: -10px;
    }
  }
}

.mail-forwarding-slogan {
  span {
    font-size: 110px;
    line-height: 1;
    letter-spacing: -6.55px;
  }
}

.same-day-scan-slogan,
.same-day-scan-slogan2 {
  position: absolute;
  z-index: 2;

  span {
    &:nth-child(1) {
      font-size: 120px;
      line-height: 1;
      letter-spacing: -6.55px;
    }

    &:nth-child(2) {
      font-size: 80px;
      line-height: 1;
      letter-spacing: -5px;
    }
  }
}

.same-day-scan-slogan {
  bottom: 70px;

  span {
    &:nth-child(3) {
      font-size: 75px;
      line-height: 2.5;
      letter-spacing: -3.75px;
    }
  }
}

.same-day-scan-slogan2 {
  bottom: 90px;

  span {
    &:nth-child(3) {
      font-size: 64px;
      line-height: 2.5;
      letter-spacing: -3.2px;
    }
  }
}

.prime-london-business-address-slogan {
  position: absolute;
  bottom: 85px;

  span {
    line-height: 1;

    &:nth-child(1) {
      font-size: 161px;
      letter-spacing: -8.05px;
    }

    &:nth-child(2) {
      font-size: 141px;
      letter-spacing: -7.05px;
    }

    &:nth-child(3) {
      font-size: 76px;
      letter-spacing: -3.8px;
    }
  }
}

/*! purgecss end ignore */
.coworking-text {
  span {
    line-height: 1;

    &:first-child {
      letter-spacing: -4.85px;
    }

    &:nth-child(2) {
      letter-spacing: -4.5px;
    }

    &:nth-child(3) {
      letter-spacing: -6.1px;
      line-height: 0.9;
    }
  }
}

.evolving-platform-text {
  font-size: 150px;
  letter-spacing: -7.5px;
  line-height: 1;
}

.step-card {
  &__large-text {
    line-height: 1.1;
    letter-spacing: -15px;
    padding-right: 15px;
    display: inline;
  }
}

a.a-unstyled,
a.a-unstyled:hover {
  color: inherit;
  text-decoration: inherit;
}

@include media-breakpoint-up(xxxl) {
  .search-post-text {
    font-size: 152px;
    line-height: 130px;
    letter-spacing: -7.6px;
  }
  .h1 {
    &--small {
      font-size: 54px;
      line-height: 57px;
      letter-spacing: -2.7px;
    }
  }
  .h2 {
    &--sm-on-desktop {
      font-size: 44px;
      letter-spacing: -1.1px;
    }
  }
  .h3 {
    &--large {
      font-size: 44px;
      line-height: 52px;
      letter-spacing: -2.2px;
    }

    &--small {
      font-size: 38px;
      line-height: 42px;
      letter-spacing: -1.9px;
    }
  }
  .h4 {
    &--large {
      font-size: 29px;
      line-height: 36px;
      letter-spacing: 0;
    }

    &--small {
      font-size: 26px;
      letter-spacing: -1.3px;
    }
  }
  .text-xxxl {
    &--large {
      font-size: 31px;
      line-height: 33px;
      letter-spacing: -1.55px;
    }

    &--large2 {
      font-size: 31px;
      line-height: 43px;
      letter-spacing: -1.55px;
    }
  }
  .display-1 {
    &--large {
      font-size: 97px;
      letter-spacing: -4.85px;
      line-height: 102px;
    }

    &--extra-large {
      font-size: 105px;
      line-height: 0.8;
      letter-spacing: -5.25px;
    }
  }
  .display-2 {
    &--large {
      font-size: 67px;
      line-height: 1.35;
      letter-spacing: -3.35px;
    }

  }
  .plan-checkout {
    .h3 {
      font-size: 34px;
      letter-spacing: -1.7px;
    }
  }
  .no-setup-text {
    line-height: 0.8;

    span {
      &:first-child {
        font-size: 394px;
        letter-spacing: -19.7px;
      }

      &:nth-child(2) {
        font-size: 195px;
        letter-spacing: -9.75px;
      }

      &:nth-child(3) {
        font-size: 265px;
        letter-spacing: -13.25px;
      }
    }
  }
  .no-setup-or-handling-fees-text {
    span {
      font-size: 130px;
      letter-spacing: -6.5px;
      line-height: 138px;

      &:first-child {
        font-size: 394px;
        letter-spacing: -19.7px;
        line-height: 0.7;
        margin-left: -25px;
      }
    }
  }
  .coworking-text {
    span {
      line-height: 1;

      &:first-child {
        font-size: 97px;
      }

      &:nth-child(2) {
        font-size: 90px;
      }

      &:nth-child(3) {
        font-size: 122px;
      }
    }
  }
  .phone-plan-card-text {
    font-size: 95px;
    line-height: 82px;
    letter-spacing: -4.75px;

    span:first-child {
      font-size: 115px;
      line-height: 99px;
      letter-spacing: -5.75px;
    }

    span:last-child {
      letter-spacing: -7px;
    }
  }
  .step-card {
    &__large-text {
      font-size: 300px;
    }
  }
  /*! purgecss start ignore */
  .ten-minutes-card {
    &--large {
      .ten-minutes-text {
        &--option1 {
          font-size: 629px;
          line-height: 0.75;
          letter-spacing: -62.9px;
          margin-left: -85px;
        }

        &--option2 {
          font-size: 605px;
          line-height: 0.75;
          letter-spacing: -60.5px;
          margin-left: -105px;
        }
      }
    }
  }
  /*! purgecss end ignore */
  .lead {
    &--md {
      font-size: 22px;
      line-height: 36px;
      letter-spacing: 0;
    }
  }
  .go-paperless-text {
    span {
      &:first-child {
        font-size: 365px;
        letter-spacing: -18.25px;
        line-height: 1;
      }

      &:nth-child(2) {
        font-size: 108px;
        letter-spacing: -5.4px;
        line-height: 0.4;
      }
    }
  }
}

@include media-breakpoint-down(xxxl) {
  .display-4,
  .quote {
    font-size: 28px;
  }
  .display-5 {
    font-size: 30px;
  }
  .search-post-text {
    font-size: 130px;
    line-height: 115px;
    letter-spacing: -7.6px;
  }
  .go-paperless-text {
    span {
      &:first-child {
        font-size: 335px;
        letter-spacing: -18.25px;
        line-height: 1;
      }

      &:nth-child(2) {
        font-size: 100px;
        letter-spacing: -5.4px;
        line-height: 0.4;
      }
    }
  }
  .h1,
  h1 {
    line-height: 54px;
    font-size: 50px;
  }
  .h2,
  h2 {
    font-size: 40px;
    line-height: 46px;
  }
  .h3,
  h3 {
    font-size: 32px;
    line-height: 36px;
  }
  .h4,
  h4 {
    font-size: 24px;

    &--ls {
      line-height: 37.7px;
    }
  }
  .lead {
    font-size: 19px;
    letter-spacing: -0.4px;
    line-height: 27px;
  }
  .lead-large,
  .lead-large-desktop {
    font-size: 21px;
    line-height: 25px;
    letter-spacing: -1.2px;
  }
  .text-xxxl {
    &--large2 {
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -1.2px;
    }
  }
  .plan-text {
    font-size: 19px;
  }
  .display-1 {
    font-size: 72px;
    line-height: 76px;

    &--large {
      font-size: 80px;
      letter-spacing: -4.85px;
      line-height: 85px;
    }

    &--extra-large {
      font-size: 90px;
      line-height: 0.9;
      letter-spacing: -5.25px;
    }
  }
  .display-2 {
    &--large {
      font-size: 52px;
      line-height: 1.25;
      letter-spacing: -3.35px;
    }
  }
  .no-setup-text {
    line-height: 0.8;

    span {
      &:first-child {
        font-size: 364px;
        letter-spacing: -19.7px;
      }

      &:nth-child(2) {
        font-size: 165px;
        letter-spacing: -9.75px;
      }

      &:nth-child(3) {
        font-size: 235px;
        letter-spacing: -13.25px;
      }
    }
  }
  .no-setup-or-handling-fees-text {
    span {
      font-size: 115px;
      letter-spacing: -6.5px;
      line-height: 123px;

      &:first-child {
        font-size: 354px;
        letter-spacing: -19.7px;
        line-height: 0.7;
        margin-left: -20px;
      }
    }
  }
  .coworking-text {
    span {
      &:first-child {
        font-size: 80px;
      }

      &:nth-child(2) {
        font-size: 75px;
      }

      &:nth-child(3) {
        font-size: 100px;
      }
    }
  }
  .phone-plan-card-text {
    font-size: 85px;
    line-height: 75px;
    letter-spacing: -4.75px;

    span:first-child {
      font-size: 102px;
      line-height: 86px;
      letter-spacing: -5.75px;
    }

    span:last-child {
      letter-spacing: -7px;
    }
  }
  .ten-minutes-text {
    font-size: 400px;
  }
  /*! purgecss start ignore */
  .ten-minutes-card {
    &--large {
      .ten-minutes-text {
        &--option1 {
          line-height: 1.2;
        }

        &--option2 {
          margin-top: 85px;
          line-height: 0.7;
        }
      }
    }
  }
  .mail-handling-slogan {
    font-size: 90px;
    line-height: 97px;
    margin-left: 50px;

    span {
      &:first-child {
        font-size: 191px;
        line-height: 160px;
      }
    }
  }
  .mail-forwarding-slogan {
    text-align: center;

    span {
      font-size: 80px;

    }
  }
  /*! purgecss end ignore */
  .step-card {
    &__large-text {
      font-size: 250px;
    }
  }
  .evolving-platform-text {
    font-size: 100px;
    letter-spacing: -4.5px;
  }
}

@include media-breakpoint-up(xxl) {
  .display-2 {
    &--medium {
      font-size: 60px;
      line-height: 72px;
      letter-spacing: -1.5px;
    }

    &--medium2 {
      font-size: 58px;
      line-height: 61px;
      letter-spacing: -2.9px;
    }
  }
}

@include media-breakpoint-down(xxl) {
  .display-4,
  .quote {
    font-size: 24px;
  }
  .display-5 {
    font-size: 26px;
  }
  .search-post-text {
    font-size: 100px;
    line-height: 88px;
    letter-spacing: -4.6px;
  }
  .go-paperless-text {
    span {
      &:first-child {
        font-size: 275px;
        letter-spacing: -18.25px;
        line-height: 1;
      }

      &:nth-child(2) {
        font-size: 75px;
        letter-spacing: -3.4px;
        line-height: 0.4;
      }
    }
  }
  .display-1 {
    font-size: 60px;
    line-height: 64px;

    &--large,
    &--extra-large {
      font-size: 70px;
      letter-spacing: -4.85px;
      line-height: 75px;
    }
  }
  .display-2 {
    &--medium,
    &--medium2 {
      font-size: 46px;
      letter-spacing: -1.5px;
    }

    &--medium {
      line-height: 58px;
    }

    &--medium2 {
      line-height: 51px;
    }

    &--extra-large {
      font-size: 32px;
    }
  }
  .phone-plan-card-text {
    font-size: 75px;
    line-height: 65px;
    letter-spacing: -4.75px;

    span:first-child {
      font-size: 92px;
      line-height: 76px;
      letter-spacing: -6px;
    }

    span:last-child {
      letter-spacing: -6.5px;
    }
  }
  /*! purgecss start ignore */
  .mail-handling-slogan {
    margin-left: 0;
  }
  .same-day-scan-slogan {
    span {
      &:nth-child(3) {
        font-size: 57px;
      }
    }
  }
  .same-day-scan-slogan2 {
    span {
      &:nth-child(3) {
        font-size: 48px;
      }
    }
  }
  .prime-london-business-address-slogan {
    span {
      &:nth-child(1) {
        font-size: 131px;
      }

      &:nth-child(2) {
        font-size: 111px;
      }

      &:nth-child(3) {
        font-size: 60px;
      }
    }
  }
  .ten-minutes-card {
    &--large {
      .ten-minutes-text {
        &--option2 {
          margin-top: 300px;
        }
      }
    }
  }
  /*! purgecss end ignore */
  .coworking-text {
    span {
      &:first-child {
        font-size: 70px;
      }

      &:nth-child(2) {
        font-size: 65px;
      }

      &:nth-child(3) {
        font-size: 86px;
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .display-4 {
    font-size: 20px;
  }
  .display-5 {
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -1.6px;

    &--small-mobile {
      font-size: 21px;
      line-height: 32px;
      letter-spacing: -1.5px;
    }

    &--large-mobile {
      font-size: 28px;
      line-height: 32px;
      letter-spacing: -1.6px;
    }
  }
  .go-paperless-text {
    span {
      &:first-child {
        font-size: 181px;
        letter-spacing: -9.05px;
        line-height: 1;
      }

      &:nth-child(2) {
        font-size: 77px;
        letter-spacing: -5.5px;
        line-height: 0.8;
      }
    }
  }
  .lead {
    &-large,
    &-large-desktop {
      font-size: 21px;
      letter-spacing: -0.53px;
    }
  }
  .display-1 {
    font-size: 50px;
    line-height: 54px;

    &--large {
      font-size: 55px;
      letter-spacing: -4.85px;
      line-height: 60px;
    }
  }
  .plan-checkout {
    h4,
    h5 {
      font-size: 21px;
      letter-spacing: -1.05px;
    }
  }
  .no-setup-text {
    line-height: 0.85;

    span {
      &:first-child {
        font-size: 304px;
        letter-spacing: -11.15px;
      }

      &:nth-child(2) {
        font-size: 140px;
        letter-spacing: -5.5px;
      }

      &:nth-child(3) {
        font-size: 200px;
        letter-spacing: -7.5px;
      }
    }
  }
  .no-setup-or-handling-fees-text {
    span {
      font-size: 80px;
      letter-spacing: -6.5px;
      line-height: 88px;

      &:first-child {
        font-size: 254px;
        letter-spacing: -19.7px;
        line-height: 0.7;
        margin-left: -20px;
      }
    }
  }
  .phone-plan-card-text {
    font-size: 60px;
    line-height: 55px;
    letter-spacing: -1.5px;

    span:first-child {
      font-size: 80px;
      line-height: 69px;
      letter-spacing: -5.75px;
    }

    span:last-child {
      letter-spacing: -3.5px;
    }
  }
  /*! purgecss start ignore */
  .mail-handling-slogan {
    font-size: 90px;
    line-height: 97px;

    span {
      &:first-child {
        font-size: 191px;
        line-height: 160px;
      }
    }
  }
  .same-day-scan-slogan,
  .same-day-scan-slogan2 {
    span {
      &:nth-child(1) {
        font-size: 120px;
        letter-spacing: -3.25px;
      }

      &:nth-child(2) {
        font-size: 80px;
        letter-spacing: -7.2px;
        margin-left: -5px;
      }
    }
  }
  .same-day-scan-slogan {
    span {
      &:nth-child(3) {
        font-size: 37px;
        letter-spacing: -1.85px;
      }
    }
  }
  .prime-london-business-address-slogan {
    position: initial;
    text-align: center;
    margin-top: 250px;
    margin-bottom: 10px;

    span {
      &:nth-child(1) {
        font-size: 79px;
        letter-spacing: -3.95px;
      }

      &:nth-child(2) {
        font-size: 69px;
        letter-spacing: -3.45px;
      }

      &:nth-child(3) {
        font-size: 37px;
        letter-spacing: -1.85px;
      }
    }
  }
  .ten-minutes-text {
    font-size: 292px;
    letter-spacing: -29.2px;
    margin-left: -45px;
  }
  .ten-minutes-card {
    &--large {
      .ten-minutes-text {
        &--option1 {
          line-height: 1.9;
        }

        &--option2 {
          margin-top: 260px;
        }
      }
    }
  }
  /*! purgecss end ignore */
  .coworking-text {
    span {
      &:first-child {
        font-size: 48px;
        letter-spacing: -2.4px;
      }

      &:nth-child(2) {
        font-size: 44px;
        letter-spacing: -2.2px;
      }

      &:nth-child(3) {
        font-size: 60px;
        letter-spacing: -3px;
      }
    }
  }
  .evolving-platform-text {
    font-size: 77px;
    line-height: 79.5px;
    letter-spacing: -6.6px;
  }
}

@include media-breakpoint-down(xl) {
  .display-1 {
    font-size: 48px;
    line-height: 51px;
    letter-spacing: -2.4px;

    &--extra-large {
      font-size: 52px !important;
      line-height: 0.85;
      letter-spacing: -2.6px !important;
    }

    &--large-on-mobile {
      font-size: 60px;
      line-height: 62px;
      letter-spacing: -3px;
    }

    &--large-on-mobile2 {
      font-size: 60px;
      line-height: 62px;
      letter-spacing: -4px;
    }
  }
  .display-2 {
    &--large-mobile {
      font-size: 52px;
      line-height: 1.25;
      letter-spacing: -3.35px;
    }

    &--large {
      font-size: 38px;
      line-height: 1;
      letter-spacing: -1.9px;
    }

    &--medium,
    &--medium2 {
      font-size: 43px;
      line-height: 52px;
    }

    &--medium {
      letter-spacing: -1.07px;
    }

    &--medium2 {
      letter-spacing: -2.15px;
    }
  }
  .h1,
  h1 {
    font-size: 48px;
    line-height: 52px;

    &--mobile-small {
      font-size: 32px;
      letter-spacing: -1.6px;
    }

    &--mobile-extra-small {
      font-size: 26px;
      letter-spacing: -1.3px;
    }
  }
  .h2,
  h2 {
    font-size: 28px;
    letter-spacing: -1.4px;
    line-height: 32px;

    &--small-on-mobile {
      font-size: 26px;
      line-height: 32px;
      letter-spacing: -1.3px;
    }

    &--extra-small-on-mobile {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -1.2px;
    }

    &--lg-on-mobile {
      font-size: 33px;
      letter-spacing: -0.82px;
    }

    &--lg-on-mobile2 {
      font-size: 32px;
      line-height: 44px;
      letter-spacing: -1.6px;
    }
  }
  .h4,
  h4 {
    font-size: 21px;
    letter-spacing: -0.53px;

    &--mobile-large {
      font-size: 24px;
      line-height: 34px;
      letter-spacing: -1.6px;;
    }

    &--ls-mobile-large {
      letter-spacing: -1.05px;
    }

    &--ls {
      line-height: 33px;
    }

    &--ls3 {
      line-height: 26px;
      letter-spacing: -1.6px;
    }
  }
  .per-month-text {
    letter-spacing: -0.4px;
  }

  .lead-large-desktop {
    font-size: 18px;
    letter-spacing: -0.4px;
    line-height: 21px;
  }
  .plan-text {
    font-size: 16px;
  }
  .step-card {
    &__large-text {
      font-size: 200px;
    }
  }
  .card-title {
    font-size: 40px;
  }
  .sm-text-medium {
    font-size: 16px;
  }

  .sm-text-large {
    font-size: 18px;
  }
  .no-setup-text {
    line-height: 0.85;

    span {
      &:first-child {
        font-size: 200px;
        letter-spacing: -11.15px;
      }

      &:nth-child(2) {
        font-size: 95px;
        letter-spacing: -5.5px;
      }

      &:nth-child(3) {
        font-size: 130px;
        letter-spacing: -7.5px;
      }
    }
  }
  .no-setup-or-handling-fees-text {
    span {
      font-size: 60px;
      letter-spacing: -6.5px;
      line-height: 68px;

      &:first-child {
        font-size: 180px;
        letter-spacing: -19.7px;
        line-height: 0.7;
        margin-left: -10px;
      }
    }
  }
  /*! purgecss start ignore */
  .mail-handling-slogan {
    text-align: center;
  }
  .same-day-scan-slogan,
  .same-day-scan-slogan2 {
    text-align: center;
    position: initial;
    margin-top: 55px;
  }
  .same-day-scan-slogan2 {
    margin-bottom: 5px;
  }
  /*! purgecss end ignore */
}

.fs-18 {
  font-size: 18px;
}

@include media-breakpoint-down(lg) {
  .text-xxl {
    font-size: 21px;
  }
  .quote {
    font-size: 21px;
  }
  .display-4 {
    font-size: 18px;
  }
  .display-3 {
    font-size: 28px;
  }
  .search-post-text {
    font-size: 78px;
    line-height: 72px;
    letter-spacing: -3.9px;
  }
  .h1,
  h1 {
    font-size: 40px;
    line-height: 44px;
    letter-spacing: -2px;

    &--ls-small-on-mobile {
      letter-spacing: -1px;
    }

    &--small-on-mobile2 {
      font-size: 36px;
      line-height: 52px;
      letter-spacing: -1.6px;
    }

    &--small-on-mobile {
      font-size: 38px;
      line-height: 44px;
      letter-spacing: -1.9px;
    }

    &--extra-small-on-mobile {
      font-size: 28px;
      line-height: 32px;
      letter-spacing: -1.5px;
    }

    &--large-mobile {
      font-size: 48px;
      line-height: 52px;
    }
  }
  .h3,
  h3 {
    font-size: 28px;
    letter-spacing: -1.4px;
    line-height: 32px;

    &--ls-mobile-2 {
      letter-spacing: -1.7px;
    }
  }
  .h4,
  h4 {
    &--small {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.45px;
    }

    &--ls2 {
      letter-spacing: -0.9px;
    }

    &--mobile-lh2 {
      line-height: 32px;
      letter-spacing: -1.5px;
    }
  }
  .lead {
    letter-spacing: -0.4px;

    &-large-mobile {
      font-size: 19px !important;
      line-height: 26px !important;
      letter-spacing: -0.47px !important;

      &--lh-small {
        line-height: 24px !important;
      }
    }

    &-extra-large-mobile {
      font-size: 21px !important;
      line-height: 26px !important;
      letter-spacing: -1.05px !important;
    }

    &-extra-large-mobile2 {
      font-size: 21px !important;
      line-height: 26px !important;
      letter-spacing: -.53px !important;
    }

    &-extra-large-mobile {
      font-size: 21px !important;
      line-height: 26px !important;
      letter-spacing: -1.05px !important;
    }

    &-extra-large-mobile3 {
      font-size: 21px !important;
      line-height: 26px !important;
      letter-spacing: -1.6px !important;
    }

    &-extra-large-mobile4 {
      font-size: 21px !important;
      line-height: 32px !important;
      letter-spacing: -1.5px !important;
    }
  }
  .text-xxxl {
    &--large2 {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.45px;
    }
  }
  .per-month-text {
    letter-spacing: -0.4px;
  }
  .no-setup-text {
    span {
      &:first-child {
        font-size: 223px;
        letter-spacing: -11.15px;
        line-height: 0.85;
      }

      &:nth-child(2) {
        font-size: 110px;
        letter-spacing: -5.5px;
        line-height: 0.5;
      }

      &:nth-child(3) {
        font-size: 150px;
        letter-spacing: -7.5px;
        line-height: 1.05;
      }
    }
  }
  .no-setup-or-handling-fees-text {
    span {
      font-size: 74px;
      letter-spacing: -3.7px;
      line-height: 82px;

      &:first-child {
        font-size: 223px;
        letter-spacing: -11.15px;
        line-height: 0.7;
        margin-left: -15px;
      }
    }
  }
  .text-medium {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.4px;

    &--large-mobile, &3 {
      font-size: 21px;
      line-height: 26px;
      letter-spacing: -1.6px;
    }
  }
  .text-small-m,
  .text-medium {
    &--mobile-small {
      font-size: 11px;
      line-height: 14px;
      letter-spacing: -0.28px;
    }
  }
  .step-card {
    &__large-text {
      font-size: 160px;
      letter-spacing: -8px;
      padding-right: 8px;
    }
  }
  /*! purgecss start ignore */
  .ten-minutes-card {
    &--large {
      .ten-minutes-text {
        &--option1 {
          line-height: 0.8;
        }

        &--option2 {
          margin-top: 0;
        }
      }
    }
  }
  /*! purgecss end ignore */
}

@include media-breakpoint-down(md) {
  /*! purgecss start ignore */
  .mail-handling-slogan {
    text-align: left;
    font-size: 58px;
    line-height: 59px;
    letter-spacing: -2.9px;

    span {
      &:first-child {
        font-size: 120px;
        line-height: 105px;
        letter-spacing: -6px;
        margin-left: -5px;
      }
    }
  }
  /*! purgecss end ignore */
  .robot-head-card-footer {
    bottom: 30px;

    .display-1 {
      font-size: 41px;
      letter-spacing: -2.05px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .display-5 {
    font-size: 21px;
    line-height: 26px;
  }
  .display-4 {
    font-size: 14px;
    line-height: 26px;
  }
  h1 {
    font-size: 28px;
  }
  .h4,
  h4 {
    &--mobile-small {
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.8px;
    }
  }
  .lead,
  .lead-large-desktop {
    font-size: 16px;
    letter-spacing: -0.4px;
    line-height: 19px;

    &--ls-lg-on-mobile {
      letter-spacing: -0.8px;
    }
  }
  .lead-small-mobile {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.7px;
  }
  .lead-extrasmall-mobile {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.3px;
  }
  .text-xl {
    &--ls-mobile-sm {
      letter-spacing: -0.53px;
    }
  }
  .plan-text {
    font-size: 11px;
    letter-spacing: -0.28px;
  }
  .phone-plan-card-text {
    font-size: 46px;
    line-height: 40px;
    letter-spacing: -2.3px;

    span:first-child {
      font-size: 56px;
      line-height: 48px;
      letter-spacing: -2.8px;
    }

    span:last-child {
      font-size: 44px;
      letter-spacing: -2.2px;
    }
  }
  /*! purgecss start ignore */
  .ten-minutes-text {
    &--option2 {
      font-size: 273px;
      letter-spacing: -27.3px;
      margin-left: -55px;
    }
  }
  .same-day-scan-slogan,
  .same-day-scan-slogan2 {
    text-align: start;
  }
  .ten-minutes-card {
    &--option2 {
      .display-1 {
        font-size: 41px;
        letter-spacing: -2.05px;
      }
    }

    &--large {
      .ten-minutes-text {
        &--option1 {
          font-size: 312px;
          line-height: 0.65;
          letter-spacing: -31.2px;
        }

        &--option2 {
          font-size: 300px;
          line-height: 0.8;
          letter-spacing: -30px;
        }
      }
    }
  }
  /*! purgecss end ignore */
}

.text-strike {
  text-decoration: line-through;
}

.error-message {
  color: $danger;
}

.partners-banner {
  font-size: 28px;
  line-height: 33px;
}
