.collapsible {
  border-bottom: 1px solid $grey2;

  &__header {
    margin-top: 27px;
    margin-bottom: 26px;
    user-select: none;
    align-items: center;
    -webkit-appearance: initial;

    h4 {
      margin-bottom: 0;
    }
  }

  a {
    text-decoration: none;
    color: #333;
  }

  .paymentmethod-body {
    padding: 17px 20px;
    background-color: #fafafa;
  }
}

@include media-breakpoint-down(lg) {
  .animated-accordion {
    .collapsible:first-child {
      border-top: 1px solid $grey2;
    }
  }
}

.paymentmethod-radiogroup {
  padding: 10px 20px;
  gap: 4px;
  display: flex;
  border-bottom: 1px solid $grey2;
}

.accordion-items-container {
  padding-bottom: 1em;
}

@include media-breakpoint-down(sm) {
  .collapsible {
    &__header {
      min-height: 64px;
      margin-top: 0.45em;
      margin-bottom: 0.45em;
    }
  }
}

@keyframes slideaway {
  from { display: block; opacity: 1; }
  to { opacity: 0;}
}

@keyframes slidepresent {
  from { display: none; opacity: 0 }
  to { opacity: 1;}
}

.konnect-accordion-image-container {
  @include media-breakpoint-up(lg) {
    height: 410px;
  }
  @include media-breakpoint-up(xl) {
    height: 491px;
  }
  @include media-breakpoint-up(xxl) {
    height: 606px;
  }
}

.konnect-accordion-image.hide {
  //animation: slideaway 500ms;
  display: none;
}

.konnect-accordion-image.display {
  animation: slidepresent 500ms;
  display: block;
}

@keyframes arrowUpAnimation {
  0% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

.arrowDownAnimation{
  animation-name: arrowDownAnimation;
  animation-duration: 500ms;
}

@keyframes arrowDownAnimation {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(180deg);
  }
}

.arrowUpAnimation{
  animation-name: arrowDownAnimation;
  animation-duration: 500ms;
}
