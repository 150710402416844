.sticky-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  border-bottom: 1px solid #e8e8ed;
  transform: translateY(-100%);
  transition: transform .5s ease-in-out;

  &--visible {
    transform: translateY(0);
  }
}
