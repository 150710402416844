.checkout-page {
  &__checkout-header {
    display: flex;
    flex-direction: row;
    background-color: $grey3;
    padding: 10px 20px 8px;
    align-items: center;
    border-bottom: 1px solid $grey5;
    .title-container {
      font-size: 13px;
      letter-spacing: -0.65px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }
  }
}
.checkout-container {
  .contact-form {
    .header-text {
      font-size: 18px;
      letter-spacing: -0.45px;
      display: block;
    }
    .name-inputs {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 1.5em;

      .input {
        width: 100%;
      }
    }

    hr {
      margin-top: 2em;
      margin-bottom: 2em;
    }
  }

  .order-summary {
    .text-small-m {
      letter-spacing: -0.7px;
    }

    &__card {
      border-radius: 12px;
      padding-top: 19px;
      padding-bottom: 20px;

      .close-button {
        display: flex;
        background-color: transparent;
        align-self: flex-end;
        border: 0;
      }

      .plan-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 14px;
        .title {
          font-size: 21px;
          letter-spacing: -1.05px;
          font-weight: bold;
        }
      }
      form {
        .coupon-input {
          font-size: 17px;
          letter-spacing: -0.85px;
          border: none;
          border-radius: 22px;
        }
      }
      .billing-type-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 0.8rem;
        .btn {
          flex: 1;
          height: 32px;
          align-items: center;
          justify-content: center;
          display: flex;
          padding-left: 8px;
          padding-right: 8px;
        }
      }

      .total-price-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        align-self: flex-end;
      }
      .addons-list-container {
        margin-bottom: 22px;
        .addons-row {
          margin-top: 11px;
          gap: 6px;
        }
      }
    }

    .addons-title-container {
      text-align: left;

      .title {
        margin: 34px 0px;
        color: $black;
      }

      .info-ribbon {
        background-color: rgba(27, 113, 227, 0.2);
        border-radius: 12px;
        padding: 8.25px 28px;
        font-weight: 600;
        font-size: 17px;
        letter-spacing: -0.85px;
      }
    }
  }

  hr {
    background-color: $grey5;
    height: 1px;
    opacity: 1;
  }
}

.total-price-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  // margin-bottom: 14px;

  span {
    font-weight: normal;
  }

  .total-price {
    font-size: 34px;
    letter-spacing: -1.7px;
    font-weight: 600;
    line-height: 30px;
  }
}

.rate-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;

  .rate {
    font-size: 28px;
    letter-spacing: -1.4px;
    font-weight: bold;
    padding: 0;
    margin: 0;
    line-height: 28px;
  }
  .rate-lg {
    font-size: 28px;
    font-weight: 600;
    margin-left: 10px;
  }

  .period {
    font-size: 12px;
    line-height: 1;
    letter-spacing: -0.3px;
    align-self: flex-end;
  }

  .remove-btn {
    font-size: 12px;
    padding: 0px;
  }
}
.breadcrumb-container {
  background-color: $white;
  padding: 0px 20px 18px 20px;
  margin-bottom: 0px;

  .breadcrumb-item {
    font-size: 11px;
    letter-spacing: -0.55px;
    &:before {
      content: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' fill='none' viewBox='0 0 15 15' %3E%3Cpath fill='%231D1D1F' d='M4.847.305l6.933 6.686a.7.7 0 010 1.018l-6.933 6.686a1.112 1.112 0 01-1.53 0 1.018 1.018 0 010-1.475l5.93-5.72-5.931-5.718a1.019 1.019 0 010-1.476 1.112 1.112 0 011.53 0' %3E%3C/path%3E%3C/svg%3E");
      color: #1d1d1f;
    }
    &:first-child:before {
      content: "";
    }
  }
  .breadcrumb-item_previous {
    color: #1b71e3;
  }
  .breadcrumb-item_active {
    color: #1d1d1f;
  }
  .breadcrumb-item_next {
    color: #939393;
  }
}
//Addon Card
.checkout-addon-card {
  display: flex;
  flex-direction: row;
  border: 1px solid #939393;
  border-radius: 12px;
  opacity: 1;
  background-color: $white;
  padding: 16px 10px 16px 20px;
  justify-content: space-between;
  text-align: left;
  align-items: center;
  gap: 2vw;

  .title-description-container {
    display: flex;
    flex-direction: column;

    .title {
      font-size: 18px;
      letter-spacing: -0.85px;
      font-weight: bold;
    }

    .description {
      font-size: 12px;
      letter-spacing: -0.6px;
    }
  }
  .addon-price-container {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    .offer-text {
      font-size: 24px;
      letter-spacing: -1.2px;
      color: $pink2;
      font-weight: bold;
      min-width: max-content;
      text-align: right;
    }

    &__rate-block {
      flex-direction: column;
      display: flex;
      text-align: right;

      .addon-rate {
        font-size: 24px;
        font-weight: bold;
        line-height: 10px;
      }

      .addon-duration {
        font-size: 12px;
        line-height: 25px;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .checkout-container {
    max-width: 100%;
    .contact-form {
      padding: 0 20px;
      .header-text {
        font-size: 16px;
        letter-spacing: -0.4px;
      }
      .text-xs {
        letter-spacing: -0.6px;
      }
      .text-small-m {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.6px;
      }
      .btn-sm {
        font-size: 13px;
        letter-spacing: -0.33px;
      }
      .btn-lg {
        font-size: 17px;
        letter-spacing: -0.85px;
        padding-top: 6px;
        padding-bottom: 6px;
      }
      hr {
        margin: 1.35em 0;
      }
    }

    .order-summary {
      margin-left: 0;
      background-color: $grey3;

      &__card {
        padding: 9px 20px 0;
        border-bottom: 1px solid #939393;
        border-radius: 0;
        .plan-info .title {
          font-size: 12px;
          font-weight: 600;
        }

        form {
          border: none;
          .coupon-input {
            font-size: 13px;
            letter-spacing: -0.65px;
          }
        }

        .total-price-section {
          align-items: flex-end;
        }
      }

      .addons-block {
        padding: 0 20px;
      }

      .addons-title-container .info-ribbon {
        text-align: center;
        font-size: 16px;
        letter-spacing: -0.8px;
        font-weight: 400;
      }
    }
  }

  .rate-container {
    align-items: flex-end;

    .rate {
      &__small {
        font-size: 12px;
        font-weight: 600;
        line-height: 13px;
      }
    }
  }

  .checkout-addon-card {
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
    background-color: $grey3;
    padding: 5px 10px;

    .addon-price-container {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      &__rate-block {
        flex-direction: row;
        gap: 4px;
        align-items: flex-end;

        .addon-rate {
          line-height: 32px;
        }
      }
    }
  }
}
.checkout-addon-card.condensed {
  border: none;
  padding: 0;
  background-color: white;
}
