.colour-block-container {
  width: 200px;
  border-radius: 1em;

  .colour-block {
    padding: 2em;
    border-radius: 1em 1em 0 0;
  }
}

.fit-content {
  height: fit-content;
}

.position-xl-sticky {
  position: sticky;
  top: 65px;
}

.position-lg-absolute,
.position-xxl-absolute {
  position: absolute;
}

.text-lg-semibold {
  font-weight: 600 !important;
}

@include media-breakpoint-down(sm) {
  .width-sm-inherit {
    width: -webkit-fill-available;
  }
}

@include media-breakpoint-up(lg) {
  .w-lg-auto {
    width: auto !important;
  }
}

hr {
  color: $grey2;
}

.pointer-event-none {
  pointer-events: none;
}

//https://nextjs.org/docs/api-reference/next/image#known-browser-bugs
@media not all and (min-resolution: .001dpcm) {
  img[loading="lazy"] {
    clip-path: inset(0.5px)
  }
}

.flex-1 {
  flex: 1;
}

.text-right {
  text-align: right;
}

.modal-open {
  overflow: hidden;

  body {
    overflow: hidden;
  }
}

.modal-content {
  max-height: 80vh;
  overflow-y: auto;
}

.vimeo {
  position: relative;
  padding: 56.25% 0 0;

  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.video-container {
  margin: 10px auto 0;
  position: relative;
  width: 100%;
  height: auto;

  video {
    width: 100%;
    height: 100%;
  }
}

.plus-icon {
  height: 22px;
  width: 22px;
  position: relative;

  &::before,
  &::after {
    content: " ";
    width: 22px;
    height: 2px;
    background-color: #000;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(90deg);
  }

  &::after {
    transform: translate(-50%, -50%);
  }
}

@include media-breakpoint-down(xxl) {
  .position-xxl-absolute {
    position: initial;
  }
}

@include media-breakpoint-down(xl) {
  .position-xl-sticky {
    position: initial;
  }
}
@include media-breakpoint-up(md) {
  .w-md-auto {
    width: auto !important;
  }
}

@include media-breakpoint-down(lg) {
  .position-lg-absolute {
    position: initial;
  }
  .text-lg-semibold {
    font-weight: 400 !important;
  }
}

@include media-breakpoint-down(md) {
  .mx-md-n12px {
    margin-left: -12px !important;
    margin-right: -12px !important;
  }
}

.mx-0\.5 {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.max-w-180 {
  max-width: 180px;
}

.z-index-0 {
  z-index: 0;
}

.z-index-1 {
  z-index: 1;
}

.z-index-99999 {
  z-index: 99999;
}

.w-85 {
  width: 85%;
}

.w-65 {
  width: 65%;
}

.w-27 {
  width: 27%;
}

.top-n5p {
  top: -5.5%;
}

.top-n6p {
  top: -6%;
}

.top-n8p {
  top: -8%;
}

.top-n21p {
  top: -21%;
}

.top-n23p {
  top: -23%;
}

.top-n30p {
  top: -30%;
}

.bottom-n30p {
  bottom: -30%;
}

.bottom-n50p {
  bottom: -50%;
}

.bottom-n70p {
  bottom: -70%;
}

ul, ol {
  li {
    padding-left: 20px;
  }
}

.bg-radial-gradient-purple {
  height: 1919px;
  width: 100%;
  position: absolute;
  top: 0;

  &--option3 {
    top: -200px;
  }
}

/*! purgecss start ignore */
.bg-gradient-purple5 {
  background: linear-gradient(180deg, $purple3 36.98%, $pink 100%);
}

@include media-breakpoint-down(lg) {
  .bg-gradient-purple5 {
    background: linear-gradient(102.14deg, #8530F7 36.98%, #FF4F7B 100%);
  }
}
.hide-crisp #crisp-chatbox {
  opacity: 0;
}
/*! purgecss end ignore */

@include media-breakpoint-down(md) {
  /*! purgecss start ignore */
  .bg-radial-gradient-purple2 {
    height: 463px;
    width: 100%;
    position: absolute;
    top: initial;
    transform: scale(1.2);

    &--option1 {
      bottom: -200px;
    }

    &--option2 {
      bottom: -150px;
    }

    &--option3 {
      bottom: -230px;
    }
  }
  /*! purgecss end ignore */
}
