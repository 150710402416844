.hamper-page {
  .hero-section {
    min-height: 801px;
    .robot-img {
      top:20px;
      img {
        max-height: 621px;
      }
    }
    .hamper-img {
      top: 335px;
      img {
        max-height: 466px;
      }
    }
  }
  .survey-form {
    .form-label {
    font-size: 16px;
    margin-bottom: 8px;
  }
  .form-control {
    border-radius: 8px;
    padding: 10px 16px;
  }
  hr {
    margin-top: 32px;
    margin-bottom: 0px;
    color: $grey1;
  }
  }
}



@include media-breakpoint-down(lg) {
  .hamper-page {
    .hero-section {
      min-height: 887px;
      .person-robot-img {
        top: 202px;
        img {
          max-height: 407px;
        }
      }
      .hamper-img {
        top: 619px;
        img {
          max-height: 286px;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .hamper-page {
    .hero-section {
      .person-robot-img {
        top: 180px;
      }
      .hamper-img {
        top: 580px;
      }
    }
  }
}