.paypal-accordion-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  .content-text {
    font-size: 12px;
    max-width: 340px;
    text-align: center;
  }
}

.input-radio-address {
  border-bottom: 1px solid $grey5;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
}

.contact-form {
  .payment-form-details {
    border: 1px solid $grey5;
    padding: 10px;
    border-radius: 5px;
    .payment-form-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      gap: 8px;

      .payment-form-row-inner {
        display: flex;
        flex-direction: row;
        gap: 8px;
        .payment-form-label {
          min-width: 90px;
          color: $grey5;
          display: block;
        }
        .danger-label {
          color: $danger !important;
        }
      }
      .payment-form-btn {
        padding: 0px;
        font-size: 14px;
      }
    }

    .payment-form-divider {
      margin: 9px 0px;
    }
  }

  .payment-form-container {
    margin-top: 34px;
    display: flex;
    flex-direction: column;

    .payment-form-title {
      font-size: 18px;
      font-weight: 600;
    }
  }

  .billing-address-container {
    .billing-address-title {
      font-size: 15px;
      font-weight: 600;
    }
    .billing-address-subtitle {
      font-size: 14px;
    }
    .billing-address-radio-group {
      border: 1px solid $grey5;
      border-radius: 5px;
      margin: 24px 0px;
    }

    .card > div:last-child > .input-radio-address {
      border-bottom: none;
    }
  }
  .card > div:last-child > .collapsible {
    border-bottom: none;
  }
  .card > div:last-child > .paymentmethod-radiogroup {
    border-bottom: none;
  }
}

@include media-breakpoint-down(lg) {
  .contact-form {
    .payment-form-details {
      .payment-form-row {
        font-size: 12px;

        .payment-form-row-inner {
          flex-direction: column;
          gap: 0;

          .payment-form-label {
            min-width: 80px;
          }
        }

        .payment-form-btn {
          font-size: 12px;
        }
      }
    }

    .payment-form-container {
      .payment-form-title {
        font-size: 21px;
      }
    }
    .billing-address-container {
      .billing-address-title {
        font-size: 16px;
        letter-spacing: -0.4px;
      }
      .billing-address-subtitle {
        font-size: 12px;
        letter-spacing: -0.6px;
      }
    }
  }
  .input-radio-address {
    font-size: 13px;
  }
}
