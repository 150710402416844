footer {
  background-color: $grey3;
  padding: 20px;
  position: relative;
  .footer-card {
    background-color: white;
    box-shadow: 12px 6px 40px #0048661A;
    border-radius: 18px;
    height: 100%;
  }
  .collapsible {
    &__header {
      margin-top: 0.9em;
      margin-bottom: 0.7em;
    }
  }
}
.footer {
  &__copyright {
    .text-small {
      letter-spacing: -.33px;
    }
  }
}

@include media-breakpoint-up(xl) {
  footer {
    .footer-card {
      height: 290px;
      width: 1000px;
      justify-content: end;
    }
  }
}

@include media-breakpoint-up(xxl) {
  footer {
    .footer-card {
      height: 290px;
      width: 1100px;
      justify-content: end;
    }
  }
}

@include media-breakpoint-up(xxxl) {
  footer {
    .footer-card {
      height: 306px;
      width: 1194px;
    }
    h4 {
      font-size: 32px;
      letter-spacing: -0.8px;
    }
  }
}

@include media-breakpoint-between(xl, xxxl) {
  footer {
    h4 {
      font-size: 28px;
      letter-spacing: -1.4px;
      line-height: 32px;
    }
  }
}

@include media-breakpoint-up(xl) {
  footer {
    padding-top: 13em;
    .footer-card-container {
      position: absolute;
      top: -9em;
    }
    .footer-card {
      .btn-secondary {
        position: absolute;
        bottom: 1em;
        right: 1em;
      }
      img {
        position: relative;
        margin-top: -7.5em;
      }
    }
  }
  .footer {
    &__links {
      max-width: 1600px;
      margin: auto;
    }
    &__copyright {
      justify-content: center;
    }
  }
}

@include media-breakpoint-between(lg,xl) {
  footer {
    padding-top: 10em;
    h4 {
      font-size: 24px;
    }
    .footer-card-container {
      position: absolute;
      top: -10em;
    }
    .footer-card {
      .btn-secondary {
        position: absolute;
        bottom: 1em;
        right: 1em;
      }
      &__image {
        img {
          width: 200px;
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  footer {
    position: relative;
    padding-top: 15em;
    .footer-card-container {
      position: absolute;
      top: -12em;
    }
    .footer-card {
      text-align: center;
      margin-left: 1em;
      margin-right: 1em;
      height: auto;
      padding: 9em 30px 42px 30px;
      border-radius: 6px;
      &__image {
        position: absolute;
        top: -13em;
        left: 0;
        right: 0;
        img {
          width: 250px;
        }
      }
      &__text {
        h4 {
          font-size: 28px;
          line-height: 32px;
          letter-spacing: -1.4px;
        }
      }
    }
    .copyright-text, .copyright-numbers {
      .text-small {
        font-size: 11px;
        line-height: 1;
        letter-spacing: -0.28px;
      }
    }
    .collapsible {
      &__header {
        height: auto;
        min-height: initial;
        .lead-large {
          font-size: 14px;
        }
      }
    }
  }
  .copyright-numbers {
    .d-flex {
      flex-wrap: wrap;
    }
  }
}


@include media-breakpoint-down(lg) {
  .footer {
    .footer-card {
      width: 100%;
    }
    &__copyright {
      flex-wrap: wrap;
      .copyright-text {
        width: 100%;
        padding: 0;
      }
      .copyright-numbers {
        padding-top: .5em;
        width: 100%;
        ul {
          li {
            padding-left: 0;
          }
        }
      }
    }
  }
}


@include media-breakpoint-down(md) {
  footer {
    &__copyright {
      .copyright-numbers {
        padding-top: 0;
      }
    }
    .accordion-items-container {
      padding-left: .5em;
      .link {
        font-size: 14px;
        line-height: 33px;
        letter-spacing: -0.35px;
      }
    }
    .collapsible {
      a {
        letter-spacing: -0.33px;
        line-height: 2.5;
      }
      &__header {
        h4 {
          font-size: 14px !important;
          font-weight: 700 !important;
          letter-spacing: -0.33px;
        }
      }
    }
  }
  .footer-card {
    text-align: center;
  }
}

@include media-breakpoint-down(sm) {
  footer {
    padding-top: 19em;
    .footer-container {
      border-top: 1px solid #D2D2D6;
    }
    .text-small {
      font-size: 14px;
      line-height: 28px;
      letter-spacing: -0.35px;
    }
  }
}
