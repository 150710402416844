.reviews-card {
  &__image {
    width: 370px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.marketplace-card {
  &__image {
    width: 58% !important;
    margin-left: auto;
    margin-right: auto;
  }
}

/*! purgecss start ignore */
.team-card {
  &__image-2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

/*! purgecss end ignore */

.img-transform-scale1 {
  transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
}

@include media-breakpoint-down(xxxl) {
  .max-w-xxxl-md-35 {
    max-width: 35%;
    height: auto;
  }
  .max-w-xxxl-md-40 {
    max-width: 40%;
    height: auto;
  }
  .max-w-xxxl-md-45 {
    max-width: 45%;
    height: auto;
  }
  .max-w-xxxl-md-50 {
    max-width: 50%;
    height: auto;
  }
  .max-w-xxxl-md-55 {
    max-width: 55%;
    height: auto;
  }
  .max-w-xxxl-md-60 {
    max-width: 60%;
    height: auto;
  }
  .max-w-xxxl-md-70 {
    max-width: 70%;
    height: auto;
  }
  .logo-img {
    max-width: 180px;
  }

  .marketplace-card {
    &__image {
      width: 50% !important;
    }
  }
}

@include media-breakpoint-down(xxl) {
  .reviews-card {
    &__image {
      width: 300px;
    }
  }

  .marketplace-card {
    &__image {
      width: 55% !important;
    }
  }
}

@include media-breakpoint-up(xxxl) {
  .mail-card {
    padding-bottom: 18em;

    &__image {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  /*! purgecss start ignore */
  .step-card {
    &__image {
      &--option1 {
        margin-top: -14em;

        &.step-card__image1 {
          height: 406px;
          width: auto;
          margin-top: -8em;
        }

        &.step-card__image2,
        &.step-card__image3, {
          height: 504px;
          width: auto;
        }
      }

      &--option2,
      &--option3 {
        margin-top: -13em;

        &.step-card__image3 {
          margin-top: -14.5em;
        }
      }

      &--option4 {
        margin-bottom: 27px;

        &.step-card__image1 {
          height: 531px;
          width: auto;
          margin-top: -15em;
        }

        &.step-card__image2 {
          height: 500px;
          width: auto;
          margin-top: -13em;
        }

        &.step-card__image3 {
          height: 562px;
          width: auto;
          margin-top: -17em;
        }
      }

      &--option5 {
        &.step-card__image1 {
          margin-top: -15em;
          margin-bottom: 45px;
        }

        &.step-card__image2 {
          margin-top: -15em;
          margin-bottom: 40px;
        }

        &.step-card__image3 {
          margin-top: -16em;
          margin-bottom: 32px;
        }
      }
    }
  }
  /*! purgecss end ignore */
}

@include media-breakpoint-down(xxxl) {
  .mail-card {
    padding-bottom: 14.5em;

    &__image {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  /*! purgecss start ignore */
  .step-card {
    &__image {
      &--option1 {
        margin-top: -12em;

        &.step-card__image1 {
          margin-top: -4em;
          max-height: initial;
          height: auto;
          max-width: 332px;
        }

        &.step-card__image2,
        &.step-card__image3 {
          max-height: initial;
          height: auto;
          max-width: 332px;
        }
      }

      &--option2,
      &--option3 {
        &.step-card__image1,
        &.step-card__image2 {
          height: 500px;
          width: auto;
          margin-top: -12em;
        }

        &.step-card__image3 {
          height: 520px;
          width: auto;
          margin-top: -13.5em;
        }
      }

      &--option4 {
        &.step-card__image1 {
          max-height: initial;
          height: auto;
          max-width: 375px;
          margin-top: -13em;
        }

        &.step-card__image2 {
          max-height: initial;
          height: auto;
          max-width: 350px;
          margin-top: -11em;
        }

        &.step-card__image3 {
          max-height: initial;
          height: auto;
          max-width: 390px;
          margin-top: -14em;
        }
      }

      &--option5 {
        &.step-card__image1 {
          margin-top: -13.5em;
          margin-bottom: 25px;
          max-width: 400px;
        }

        &.step-card__image2 {
          margin-top: -14em;
          margin-bottom: 32px;
          max-width: 400px;
        }

        &.step-card__image3 {
          margin-top: -16em;
          margin-bottom: 32px;
          max-width: 290px;
        }
      }
    }
  }
  /*! purgecss end ignore */
}

@include media-breakpoint-down(xxl) {
  .mail-card {
    &__image {
      width: 450px;
    }
  }
  /*! purgecss start ignore */
  .step-card {
    &__image {
      &--option2,
      &--option3 {
        &.step-card__image1,
        &.step-card__image2 {
          height: 430px;
          width: auto;
          margin-top: -12em;
        }

        &.step-card__image3 {
          height: 450px;
          width: auto;
          margin-top: -13.5em;
        }
      }

      &--option4 {
        &.step-card__image1 {
          max-height: initial;
          height: auto;
          max-width: 310px;
          margin-top: -13em;
          margin-bottom: 10px;
        }

        &.step-card__image2 {
          max-height: initial;
          height: auto;
          max-width: 300px;
          margin-top: -11.5em;
        }

        &.step-card__image3 {
          max-height: initial;
          height: auto;
          max-width: 340px;
          margin-top: -15em;
          margin-bottom: 8px;
        }
      }

      &--option5 {
        &.step-card__image1 {
          margin-top: -13.5em;
          margin-bottom: 29px;
          max-width: 350px;
        }

        &.step-card__image2 {
          margin-top: -13em;
          margin-bottom: 32px;
          max-width: 340px;
        }

        &.step-card__image3 {
          margin-top: -15em;
          margin-bottom: 32px;
          max-width: 250px;
        }
      }
    }
  }
  .team-card {
    &__image-2 {
      margin-top: -15em;
    }
  }
  /*! purgecss end ignore */
}

@include media-breakpoint-up(xl) {
  .max-h-vh {
    max-height: 100vh;
    width: auto;
  }
}

@include media-breakpoint-down(xl) {
  .reviews-card {
    &__image {
      width: 250px;
    }
  }
  .mail-card {
    &__image {
      width: 420px;
    }
  }
  /*! purgecss start ignore */
  .team-card {
    &__image-2 {
      margin-top: -13em;
    }
  }
  /*! purgecss end ignore */
}

@include media-breakpoint-down(xl) {
  /*! purgecss start ignore */
  .step-card {
    &__image {
      &--option1 {
        &.step-card__image2 {
          max-width: 300px;
        }

        &.step-card__image3 {
          max-width: 320px;
        }
      }

      &--option2 {
        &.step-card__image1 {
          height: 371px;
          margin-top: -7.6em;
          margin-bottom: -30px;
        }

        &.step-card__image2 {
          height: 496px;
          margin-top: -13.5em;
          margin-bottom: -50px;
        }

        &.step-card__image3 {
          height: 510px;
          margin-top: -13em;
          margin-bottom: -20px;
        }
      }

      &--option3 {
        &.step-card__image1 {
          height: 400px;
          margin-top: -9.5em;
          margin-bottom: -30px;
        }

        &.step-card__image2 {
          height: 469px;
          margin-top: -13.5em;
          margin-bottom: -40px;
        }

        &.step-card__image3 {
          height: 531px;
          margin-top: -13.5em;
          margin-bottom: -35px;
        }
      }

      &--option4 {
        &.step-card__image1 {
          max-width: initial;
          height: 375px;
          width: auto;
          margin-top: -8.5em;
          margin-bottom: -20px;
          padding-left: 20px;
        }

        &.step-card__image2 {
          max-width: initial;
          height: 425px;
          width: auto;
          margin-top: -12em;
          margin-bottom: -15px;
        }

        &.step-card__image3 {
          max-width: initial;
          height: 550px;
          width: auto;
          margin-top: -13em;
          margin-bottom: -45px;
        }
      }

      &--option5 {
        height: auto;

        &.step-card__image1 {
          width: 310px;
          margin-top: -8.5em;
          margin-bottom: -12px;
        }

        &.step-card__image2 {
          margin-bottom: -35px;
          width: 400px;
          max-width: initial;
        }

        &.step-card__image3 {
          max-width: 300px;
          width: 300px;
          margin-top: -13em;
          margin-bottom: -35px;
        }
      }
    }
  }
  /*! purgecss end ignore */
}

@include media-breakpoint-down(lg) {
  .img-konnect {
    width: 670px;
    max-width: initial;
  }
  .designed-for-image {
    position: absolute;
    bottom: 0;
    left: -8px;
    width: 240px;
  }
  .build-experiences-card {
    margin-top: 16em;
    min-height: 214px;

    &__image {
      position: relative;
      margin-top: -11.5em;
    }
  }
  .reviews-card {
    &__image {
      width: 200px;
    }
  }
  .max-w-lg-sm-50 {
    max-width: 50%;
  }
  .security-and-privacy-image {
    margin-top: -140px;
  }
}

@include media-breakpoint-between(md, lg) {
  /*! purgecss start ignore */
  .team-card {
    &__image-1 {
      margin-top: -7em;
    }

    &__image-2 {
      margin-top: -10em;
    }
  }
  /*! purgecss end ignore */
}

@include media-breakpoint-down(md) {
  /*! purgecss start ignore */
  .team-card {
    &__image-1 {
      position: absolute;
      right: 0;
      left: 0;
      margin-right: auto;
      margin-left: auto;
      margin-top: -3em;
      max-width: 375px;
    }

    &__image-2 {
      width: 100%;
      margin-top: -2.3em;
    }
  }

  /*! purgecss end ignore */

  .mail-card {
    padding-bottom: 19em;

    &__image {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .max-w-xxxl-md-35,
  .max-w-xxxl-md-40,
  .max-w-xxxl-md-45,
  .max-w-xxxl-md-50,
  .max-w-xxxl-md-55,
  .max-w-xxxl-md-60,
  .max-w-xxxl-md-70 {
    max-width: 100%;
  }
}

@include media-breakpoint-down(sm) {
  /*! purgecss start ignore */
  .transform-scale-sm-1\.5 {
    transform: scale(1.5);
  }
  /*! purgecss end ignore */
  .reviews-card {
    padding-bottom: 352px;

    &__image {
      width: 270px;
    }
  }
  .mail-card {
    padding-bottom: 240px;

    &__image {
      position: absolute;
      right: 0;
      bottom: 55px;
      max-width: 335px;
    }
  }
  /*! purgecss start ignore */
  .step-card {
    &__image {
      &--option1 {
        max-width: 85%;
        margin-top: -12em;

        &.step-card__image1 {
          margin-top: -7.9em;
          max-width: 100%;
        }
      }
    }
  }
  /*! purgecss end ignore */
  .max-w-lg-sm-50 {
    max-width: 100%;
    width: 100%;
  }
}


@include media-breakpoint-up(lg) {
  /*! purgecss start ignore */
  .team-card {
    &__image-1,
    &__image-2 {
      position: relative;
    }

    &__image-1 {
      margin-top: -10em;
    }
  }
  /*! purgecss end ignore */
  .build-experiences-card {
    margin-top: 22em;

    &__image {
      position: relative;
      margin-top: -13.5em;
    }
  }
  .designed-for-image {
    position: absolute;
    bottom: 0;
    left: -7px;
    width: 238px;
  }
}

@include media-breakpoint-up(xl) {
  /*! purgecss start ignore */
  .designed-for-image {
    position: absolute;
    bottom: 0;
    left: -6.5px;
    width: 249px;
  }
}

@include media-breakpoint-up(xxl) {
  .designed-for-image {
    position: absolute;
    bottom: -13px;
    left: -10px;
    width: 331px;
  }
  /*! purgecss start ignore */
  .team-card {
    &__image-2 {
      margin-top: -20em;
    }
  }
  /*! purgecss end ignore */
}

@include media-breakpoint-down(xs) {
  .img-mobile-fullwidth {
    transform: scale(1.06) !important;
    -ms-transform: scale(1.06) !important;
    -webkit-transform: scale(1.06) !important;
  }
  .img-mobile-fullwidth2 {
    transform: scale(1.15) !important;
    -ms-transform: scale(1.15) !important;
    -webkit-transform: scale(1.15) !important;
  }
  .img-mobile-fullwidth3 {
    transform: scale(1.2) !important;
    -ms-transform: scale(1.2) !important;
    -webkit-transform: scale(1.2) !important;
  }
  .img-mobile-fullwidth4 {
    transform: scale(1.25) !important;
    -ms-transform: scale(1.25) !important;
    -webkit-transform: scale(1.25) !important;
  }
  .img-mobile-fullwidth5 {
    transform: scale(1.4) !important;
    -ms-transform: scale(1.4) !important;
    -webkit-transform: scale(1.4) !important;
  }
  .img-mobile-fullwidth6 {
    transform: scale(1.1) !important;
    -ms-transform: scale(1.1) !important;
    -webkit-transform: scale(1.1) !important;
  }
  .img-mobile-fullwidth7 {
    transform: scale(1.3) !important;
    -ms-transform: scale(1.3) !important;
    -webkit-transform: scale(1.3) !important;
  }
}
