@import "variables";
@import "3rdParty/index";
@import "project/index";

.flex-row {
  display: flex;
  flex-direction: row;
}

/*! purgecss start ignore */
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box
}

.cursor-pointer {
  cursor: pointer;
}
.d-inline-block {
  display: inline-block;
}
.overflow-x-hidden {
  overflow-x: hidden;
}

.block-img {
  display: block;
  max-width: 900px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  img {
    width: 100%;
    height: auto;
  }
}
html {  scroll-behavior: auto !important;}
#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@include media-breakpoint-up(xxl) {
  main {
    > *:last-child {
      padding-bottom: 16em !important;
      &.marketplace {
        padding-bottom: 4em !important;
      }
      &.reviews-section,
      &.checkout-container {
        padding-bottom: 25em !important;
      }
      &.thankyou-cards {
        padding-bottom: 26.5em !important;
      }
      &.legal-container {
        padding-bottom: 17.5em !important;
      }
      &.spacer {
        padding-bottom: 26em !important;
      }
    }
  }
}

@include media-breakpoint-down(xxl) {
  main {
    > *:last-child {
      padding-bottom: 16em !important;
      &.marketplace {
        padding-bottom: 4em !important;
      }
      &.reviews-section,
      &.checkout-container {
        padding-bottom: 20em !important;
      }
      &.thankyou-cards,
      &.spacer {
        padding-bottom: 22em !important;
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  main {
    > *:last-child {
      &.reviews-section,
      &.checkout-container {
        padding-bottom: 12em !important;
      }
    }
  }
}

.rounded-box {
  border-radius: 26px;
}

.video-button {
  width: 82px;
  height: 82px;

  @include media-breakpoint-down(lg) {
    width: 44px;
    height: 44px;
  }
}

.rounded-video {
  display: flex;
  justify-content: center;
  align-items: center;

  video {
    border-radius: 26px;
  }
}

.react-player__shadow {
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 1;
  opacity: 0;
}

@include media-breakpoint-down(lg) {
  main {
    > *:last-child {
      padding-bottom: 25em !important;
      &.marketplace {
        padding-bottom: 4em !important;
      }
      &.reviews-section {
        padding-bottom: 28em !important;
      }
      &.checkout-container {
        padding-bottom: 32em !important;
      }
      &.legal-container {
        padding-bottom: 27.5em !important;
      }
      &.thankyou-cards,
      &.spacer {
        padding-bottom: 29em !important;
      }
    }
  }
  .rounded-box {
    border-radius: 15px;
  }
  .rounded-video {
    video {
      border-radius: 15px;
    }
  }
}

.text-muted {
  color: #888887 !important;
}
