.rich-text-blog {
  font-weight: 600;
  a {
    color: $pink2;
    text-decoration: none;
  }
  p {
    @extend .text-medium, .text-medium--lt2;
    margin-bottom: 24px;

    &:first-child {
      @extend .lead;
      letter-spacing: -0.53px;
      font-weight: 600;
    }
  }
  h2, h3, h4, h5, h6 {
    @extend .lead;
    letter-spacing: -0.53px;
    font-weight: bold;
    margin-bottom: 11px;
  }
  h2 {
    margin-top: 50px;
  }
  img {
    width: 100%;
    height: auto;
    border-radius: 15px;
  }
  ul, ol {
    font-weight: 600;
  }
  ul, ol {
    li {
      padding-left: 20px;
    }
  }
  ul {
    li::marker {
      content: "\2022";
    }
  }
  .social-icons {
    li {
      &::marker {
        content: none;
      }
      padding-left: 0;
      padding-right: 1.5em;
      img {
        height: 19px;
        width: auto;
        border-radius: 0;
      }
    }
  }
  iframe {
    @extend .mt-md-4, .mb-md-6, .mt-2, .mb-4, .px-md-11;
    width: 100%;
  }
  .article-social-icons {
    display: none;
    &:first-of-type {
      display: block;
    }
  }
  .article-image {
    @extend .mt-md-8, .mt-1;
    &:first-of-type {
      @extend .mt-0;
    }
  }
}

@include media-breakpoint-up(md) {
  .rich-text-blog {
    iframe {
      height: 260px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .rich-text-blog {
    iframe {
      height: 380px;
    }
  }
}

@include media-breakpoint-up(xxl) {
  .rich-text-blog {
    iframe {
      height: 412px;
    }
  }
}

@include media-breakpoint-down(xxxl) {
  .rich-text-blog {
    h2 {
      margin-top: 40px;
    }
  }
}

@include media-breakpoint-down(md) {
  .rich-text-blog {
    p {
      margin-bottom: 18px;
      font-weight: normal;
      &:first-child {
        font-size: 19px;
        line-height: 24px;
        letter-spacing: -0.47px;
        font-weight: 600;
      }
    }
    h2, h3, h4, h5, h6 {
      font-size: 19px;
      line-height: 24px;
      letter-spacing: -0.47px;
      margin-bottom: 14px;
    }
    h2 {
      margin-top: 12px;
    }
    ul, ol {
      font-weight: normal;
    }
    .social-icons {
      justify-content: start;
      padding: 0 !important;
      li {
        padding-right: 1em;
      }
    }
    iframe {
      height: calc(0.5 * (100vw - 20px));
    }
  }
}
