.fade-up-section {
  opacity: 0;
  transform: translate(0, 25%);
  visibility: hidden;
  transition: opacity 1s ease-out, transform 1s ease-out;
  will-change: opacity, visibility;

  &.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
    display: block;
  }
}
