.icon {
  &__absolute-top-right {
    right: 20px;
    position: absolute;
    top: 20px;
  }

  &-box {
    width: 84px;
    height: 84px;
    border-radius: 17px;
  }
}

@include media-breakpoint-up(lg) {
  .mailroom-red-h2 {
    position: relative;
    width: 351px;
    bottom: 20px;
    margin-right: 10px;
  }
}

@include media-breakpoint-up(md) {
  .social-icons {
    align-items: center;
    li {
      float: left;
      padding-right: 1em;
    }
  }
}

@include media-breakpoint-down(lg) {
  .icon {
    &-box {
      width: 62px;
      height: 62px;
      border-radius: 14px;
    }
  }
  .envelope-icon {
    width: 62px;
    height: 62px;
  }
  .mailroom-red-h2 {
    position: relative;
    width: 308px;
    margin-right: 5px;
  }
  .mailroom-question {
    font-size: 37px;
    line-height: 40px;
    letter-spacing: -1.85px;
    margin-top: 25px;
  }
}

@include media-breakpoint-down(md) {
  .social-icons {
    align-items: center;
  }
}

@include media-breakpoint-up(md) {
  .rating-logos {
    justify-content: center;
    align-items: center;
    li {
      float: left;
      padding-right: 1.5em;
    }
  }
}

@include media-breakpoint-down(md) {
  .rating-logos {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    li {
      padding-left: 0;
      padding-right: 0;
      flex: 30%;
      padding-bottom: 50px;
      display: flex;
      justify-content: center;
      &:nth-of-type(4),
      &:nth-of-type(5) {
        padding-bottom: 0;
      }
    }
  }
}






@include media-breakpoint-down(up) {
  .social-icons {
    li {
      float: left;
      padding-right: 1em;
    }
  }
}

@include media-breakpoint-down(md) {
  .icon {
    &__absolute-top-right {
      width: 88px;
    }
  }
}
