@import "~bootstrap/scss/tables";

.mp-hero-container {
  height: 690px;
  position: absolute;
  right: 225px;
  top: 20px;
  width: 822px;
}

.offer-detail-brand-logo {
  max-width: 25vw;
}

.offer-card {
  box-shadow: 2px 4px 12px rgba(0, 0, 0, .08);
  transition: all .3s cubic-bezier(0, 0, .5, 1);
  //border-color: $grey5 !important;
  width: 444px;
  border-radius: 16px !important;

  &:hover {
    box-shadow: 2px 4px 16px rgba(0, 0, 0, .16);
    transform: scale3d(1.01, 1.01, 1.01);
  }

  .color-grey5 {
    color: $grey5;
  }

  .lead {
    font-size: 1.25rem;
  }

  .product-container {
    height: 196px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .offer-image {
      object-fit: contain;
      width: 100%;
      height: auto;
    }
  }

  .border-color {
    border-color: $grey5 !important;
  }

  .title-container {
    &1{
      min-height: 110px;
    }
    &2{
      min-height: 88.8px;
    }
    >*{
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      line-height: 1.2;
    }
  }
  .description-container1,
  .description-container2 {
    height: 146px;
    >* {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 8;
      -webkit-box-orient: vertical;
    }
  }

  .description-container2 {
    margin-bottom: 21.2px;
  }
}

.category-container {
  display: flex;
  flex-wrap: wrap;

  & > .chip {
    border: none;
    border-radius: 22px;
    padding: 9px 10.5px;
    display: flex;
    background-color: $grey8 !important;

    &:hover {
      background-color: #ad3aa6 !important;
      color: #fff;
    }

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.marketplace-banner {
  .subtext {
    line-height: 1.36;
    font-size: 18px;
    letter-spacing: -1px;
    @media (max-width: 1200px) {
      font-size: 16px;
    }
  }

  .hero-image {
    top: 0px;
    left: 0px;
    width: 243px;
    height: 81px;
    position: absolute;
  }
}

.marketplace-footer {
  .footer-btn {
    width: 114px;
    height: 37px;
    border: 2px solid #ffffff;
    border-radius: 22px;
    background-color: transparent;
  }

  .subscribe-container {
    input {
      flex: 1;
      min-width: 190px;
    }

    button {
      flex: 0;
      background-color: $primary;
      color: $white;
      height: 42.5px;
      font-size: 13px;
      margin-left: -4px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-width: 0px;
      z-index: 1;
    }
  }

  @media (min-width: 767px) and (max-width: 1479px) {
    .subscribe-container {
      button {
        margin-left: 0;
        margin-top: 10px;
        border-radius: 5px;
        width: 100%;
      }
    }
  }
}

.marketplace-btn {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(142.96deg, #E7E5EB -3.54%, rgba(239, 238, 243, 0.42) 95.15%);
  box-shadow: 0 0 2px 0 #D4D4D440 inset;

  &:after {
    content: url("/images/marketplace/btn-icon.png");
    margin: 7px 0 0;
  }

  &:hover {
    color: #fff;
    background: $primary;

    &:after {
      content: url("/images/marketplace/hovered-btn-icon.png");
    }
  }
}

@include media-breakpoint-up(lg) {
  .marketplace-blog-container {
    &.blog-container {
      padding-left: 0;
    }
  }
}

@include media-breakpoint-down(md) {
  .offer-detail-brand-logo {
    max-width: 60vw;
  }
}

@include media-breakpoint-up(xxxl) {
  .offer-main-container {
    h1 {
      font-size: 48px;
    }

    h2 {
      font-size: 42px;
    }

    h3 {
      font-size: 36px;
    }
  }
}

.offer-main-container {
  @extend .lead-large-desktop, .text-semibold;

  table {
    @extend .table;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @extend .mt-9;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  p, ul, ol {
    color: $grey5;
  }

  ul, ol {
    li {
      @extend .mb-4;
    }
  }

  main {
    > *:last-child {
      padding-bottom: 5em !important;
    }
  }

  .rounded-box {
    border-radius: 17px;
    box-shadow: 0px 6px 6px #0000001a;
  }

  .divider {
    border: 1px solid $grey5;
    width: 100%;
  }

  .carousel-container {
    padding-left: 22%;
  }
}

@include media-breakpoint-down(sm) {
  .offer-card {
    //.product-container {
    //  height: 150px;
    //}

    .title-container {
      height: auto;
    }
  }
}
