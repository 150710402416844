.benefit {
  &__columns {
    margin-top: 215px;
    margin-bottom: 80px;
  }
  &__item {
    padding: 3.3em 0;
    border-top: 1px solid $grey2;
    border-bottom: 1px solid $grey2;
    .content {
      font-size: 17px;
      line-height: 25px;
      letter-spacing: -0.42px;
    }
  }
  &__character {
    position: absolute;
    right: -7em;
    bottom: -80px;
  }
}

@include media-breakpoint-down(xxl) {
  .benefit {
    &__columns {
      margin-top: 150px;
      margin-bottom: 50px;
    }
    &__character {
      width: 400px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .benefit {
    &__callout {
      padding-bottom: 10em;
    }
  }
}

@include media-breakpoint-down(xl) {
  .benefit {
    &__columns {
      margin-top: 115px;
    }
    &__character {
      width: 350px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .benefit {
    &__columns {
      margin-top: 0;
      margin-bottom: 0;
    }
    &__character {
      position: initial;
    }
  }
}

@include media-breakpoint-down(md) {
  .benefit {
    &__item {
      padding: 2em 0;
    }
    &__text {
      font-size: 21px;
      line-height: 26px;
      letter-spacing: -1.05px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .benefit {
    &__item {
      .h2 {
        font-size: 48px;
      }
      .content {
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.4px;
      }
    }
  }
}
