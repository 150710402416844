/*! purgecss start ignore */
.card {
  height: 100%;
  position: relative;
  &__bg-hm-symbol {
    background-image: url("/images/icons/homepage-hm-symbol-desktop.svg");
  }
  &__min-height-256px {
    min-height: 256px;
  }
  &--radius-md {
    border-radius: 12px;
  }
  &--radius-lg {
    border-radius: 17px;
  }
  &--padding-0,.padding-0 {
    padding: 0;
  }
  &--padding-sm,.padding-sm {
    padding: 34px 18px 15px;
  }
  &--padding-md,.padding-md {
    padding: 34px 40px 34px 40px;
  }
  &--shadow {
    box-shadow: 0 6px 6px #0000001a;
  }
}

.robot-head-card {
  //background: url('https://imgproxy.hoxtonmix.com/PvOQOsQEoYS570gLLkYmocp96kcYD2F2fyjOsb8TRCU/resizing_type:fill/dpr:1/format:svg/aHR0cHM6Ly9ob3h0b25taXguY29tL2ltYWdlcy9pY29ucy9obS1sb2dvLWNyb3Auc3Zn.svg') top left no-repeat, $gradient-fuchsia;
  &.bg-gradient-teal {
    background: url('https://imgproxy.hoxtonmix.com/PvOQOsQEoYS570gLLkYmocp96kcYD2F2fyjOsb8TRCU/resizing_type:fill/dpr:1/format:svg/aHR0cHM6Ly9ob3h0b25taXguY29tL2ltYWdlcy9pY29ucy9obS1sb2dvLWNyb3Auc3Zn.svg') top left no-repeat, $gradient-teal;
  }
}

.plug-card {
  background: url('https://imgproxy.hoxtonmix.com/PvOQOsQEoYS570gLLkYmocp96kcYD2F2fyjOsb8TRCU/resizing_type:fill/dpr:1/format:svg/aHR0cHM6Ly9ob3h0b25taXguY29tL2ltYWdlcy9pY29ucy9obS1sb2dvLWNyb3Auc3Zn.svg') top left no-repeat, $gradient-fuchsia;
  &.bg-gradient-teal {
    background: url('https://imgproxy.hoxtonmix.com/PvOQOsQEoYS570gLLkYmocp96kcYD2F2fyjOsb8TRCU/resizing_type:fill/dpr:1/format:svg/aHR0cHM6Ly9ob3h0b25taXguY29tL2ltYWdlcy9pY29ucy9obS1sb2dvLWNyb3Auc3Zn.svg') top left no-repeat, $gradient-teal;
  }
  &.bg-gradient-teal2 {
    background: url('/images/icons/hm-logo-crop-purple.svg') top left no-repeat, $gradient-teal2;
  }
  &.bg-gradient-pink {
    background: url('https://imgproxy.hoxtonmix.com/PvOQOsQEoYS570gLLkYmocp96kcYD2F2fyjOsb8TRCU/resizing_type:fill/dpr:1/format:svg/aHR0cHM6Ly9ob3h0b25taXguY29tL2ltYWdlcy9pY29ucy9obS1sb2dvLWNyb3Auc3Zn.svg') top left no-repeat, linear-gradient(146deg, $pink 0%, $yellow 100%);
    background-size: contain;
  }
  &.bg-gradient-red {
    background: url('/images/icons/hm-logo-crop-red.svg') top left no-repeat, $gradient-red;
  }
  &.bg-gradient-purple-blue {
    background: url('/images/icons/hm-logo-crop-purple.svg') top left no-repeat, linear-gradient(141deg, $purple2 0%, $blue 100%);
    background-size: contain;
  }
  &.bg-gradient-purple {
    background: url('https://imgproxy.hoxtonmix.com/PvOQOsQEoYS570gLLkYmocp96kcYD2F2fyjOsb8TRCU/resizing_type:fill/dpr:1/format:svg/aHR0cHM6Ly9ob3h0b25taXguY29tL2ltYWdlcy9pY29ucy9obS1sb2dvLWNyb3Auc3Zn.svg') top left no-repeat, linear-gradient(180deg, #8530F6 0%, #BC2CC0 100%);
    background-size: cover;
    &.evolving-platform-card {
      background: url('https://imgproxy.hoxtonmix.com/PvOQOsQEoYS570gLLkYmocp96kcYD2F2fyjOsb8TRCU/resizing_type:fill/dpr:1/format:svg/aHR0cHM6Ly9ob3h0b25taXguY29tL2ltYWdlcy9pY29ucy9obS1sb2dvLWNyb3Auc3Zn.svg') top left no-repeat, linear-gradient(100deg, #8530F6 0%, #BC2CC0 100%);
    }
  }

  &__icon {
    width: 163px;
    height: auto;
  }
}

.go-paperless-card {
  &.bg-gradient-purple {
    background: linear-gradient(100deg, $purple3 0%, $cerise 100%);
  }
}

.pioneering-virtual-office-solutions-since-2010-card {
  background: linear-gradient(102deg, $purple3 36.98%, $pink 100%);
}

.card-select {
  padding: 30px 10px;
  border-radius: 12px;
  border: 1px solid $muted;
  overflow: hidden;
  &--active {
    border: 3px solid #1B71E3;
  }
  .best-proposal {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 0;
    border-top: 90px solid #1B71E3;
    border-left: 90px solid transparent;
    &__text {
      position: absolute;
      top: -85px;
      right: 5px;
      color: #fff;
      font-size: 19px;
      font-weight: 700;
      letter-spacing: -0.47px;
      line-height: 17px;
      width: 90px;
      text-align: end;
      span {
        font-size: 13px;
        letter-spacing: -0.33px;
      }
    }
    &__icon1,
    &__icon2 {
      position: absolute;
    }
    &__icon1 {
      top: -47px;
      right: 25px;
    }
    &__icon2 {
      top: -42px;
      right: 5px;
    }
  }
}

.color-card {
  border-radius: 6px;
  height: 341px;
}

.robot-head-card {
  .robot-head {
    bottom: 0;
    position: absolute;
    right: 0;
    z-index: 1;
    &--scale {
      bottom: -15px;
      transform: scale(1.3);
      -ms-transform: scale(1.3);
      -webkit-transform: scale(1.3);
    }
  }
  &-footer {
    position: absolute;
    bottom: 45px;
    z-index: 2;
  }
}

.card-with-vertically-centered-image {
  .vertically-centered-image {
    top: 90px;
  }
  .vertically-centered-image2 {
    top: 50%;
    transform: translateY(-50%);
  }
  .vertically-centered-image,
  .vertically-centered-image2 {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: auto 0;
  }
}
.ten-minutes-card {
  .display-1 {
    line-height: 0.9;
  }
}

.plan-card-container {
  min-height: 640px;
  img {
    position: absolute;
    height: 95%;
    width: auto;
    right: 0;
    bottom: 0;
  }
  .btn {
    width: 142px;
  }
}

@include media-breakpoint-up(xxxl) {
  .go-paperless-card {
    min-height: 800px;
  }
  .exclusive-partner-card {
    min-height: 893px;
    .h2 {
      font-size: 63px;
      line-height: 1;
      letter-spacing: -3.15px;
    }
  }
  .robot-head-card {
    &-footer {
      .display-1 {
        font-size: 84px;
        letter-spacing: -4.2px;
      }
    }
  }
  .ten-minutes-card {
    &--default {
      min-height: 820px;
    }
    &--large {
      min-height: 893px;
    }
  }
  .step-card {
    &__text {
      padding: 0 10px 75px 10px;
    }
  }
}

@include media-breakpoint-down(xxxl) {
  .exclusive-partner-card {
    min-height: 790px;

    .h2 {
      font-size: 53px;
      line-height: 1;
      letter-spacing: -3.15px;
    }
  }
  .plan-card-container {
    min-height: 600px;
  }
  .ten-minutes-card {
    min-height: 730px;
  }
  .step-card {
    &__text {
      padding: 0 10px 55px 10px;
    }
  }
}

@include media-breakpoint-up(xxl) {
  .meeting-rooms-card,
  .mailroom-card {
    height: 640px;
  }
  .good-hands-card {
    height: 640px;
    margin-bottom: 10em;
  }
  .plug-card {
    .hm-symbol {
      &--small {
        width: 157px;
      }
      &--medium {
        width: 164px;
      }
      &--large {
        width: 319px;
      }
    }
  }
}

@include media-breakpoint-down(xxl) {
  .good-hands-card {
    height: 540px;
    margin-bottom: 10em;
  }
  .plan-card-container {
    min-height: 540px;
    img {
      height: 85%;
      bottom: 2em;
    }
  }
  .ten-minutes-card {
    min-height: 650px;
  }
  .crunch-card {
    height: 640px;
    .crunch-logo {
      width: 475px;
      height: auto;
    }
  }
  .hoxtonmix-crunch-card {
    height: 683px;
    margin-bottom: 17em;
  }
  .plug-card {
    .hm-symbol {
      &--small {
        width: 127px;
      }
      &--medium {
        width: 127px;
      }
      &--large {
        width: 220px;
      }
    }
  }
}
@include media-breakpoint-down(xxl) {
  .meeting-rooms-card,
  .mailroom-card {
    height: 540px;
  }
  .ten-minutes-card {
    min-height: 650px;
  }
  .exclusive-partner-card {
    min-height: 740px;
    .h2 {
      font-size: 48px;
      line-height: 1;
      letter-spacing: -3.15px;
    }
  }
  .crunch-card {
    height: 500px;
    .crunch-logo {
      width: 375px;
      height: auto;
    }
  }
  .hoxtonmix-crunch-card {
    height: 550px;
    margin-bottom: 13em;
  }
}

@include media-breakpoint-down(xl) {
  .color-card {
    height: 250px;
    border-radius: 0;
    .tide-logo {
      width: 263px;
    }
    .crunch-logo {
      width: 279px;
    }
  }
  .evolving-platform-card {
    min-height: 620px;
  }
  .exclusive-partner-card {
    min-height: 650px;
    .h2 {
      font-size: 36px;
      line-height: 1;
      letter-spacing: -1.55px;
    }
  }
  .plan-card-container {
    min-height: 450px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    img {
      right: 5em;
    }
  }
  .card-select {
    padding: 50px 5px;
  }
  .reviews-section {
    .card {
      &--padding-md,
      &.padding-md {
        padding: 34px 20px;
      }
    }
  }
  .virtual-phone-number-travels-card {
    margin-top: 20em;
  }
  .ten-minutes-card {
    min-height: 536px;
  }
  .step-card {
    position: relative;
    height: auto;
    &__text {
      padding: 20px 28px 50px 28px;
      letter-spacing: -0.42px;
      line-height: 25px;
    }
    &__item {
      margin-bottom: 15em;
    }
  }
  .step-card__container {
    .step-card__item {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .crunch-card {
    .crunch-logo {
      width: 285px;
      height: auto;
    }
  }
  .hoxtonmix-crunch-card {
    height: 465px;
    margin-bottom: 13em;
  }
  .plug-card {
    .hm-symbol {
      &--small {
        width: 78px;
      }
      &--medium {
        width: 102px;
      }
      &--large {
        width: 158px;
      }
    }
  }
}


@include media-breakpoint-up(lg) {
  .exclusive-partner-card {
    background: url('/images/banking/collect-page-10-mins-plugz-head-desktop.png') bottom right no-repeat $darkBlue;
    background-size: 100%;
  }
  .step-card {
    position: relative;
    &__text {
      letter-spacing: -0.42px;
      line-height: 25px;
      .text-medium {
        letter-spacing: -0.42px;
      }
    }
  }
  .card {
    &--padding-xl,.padding-xl {
      padding: 74px 69px 74px 69px;
    }
    &--padding-lg,.padding-lg {
      padding: 50px 40px 60px 50px;
    }
  }
}

@include media-breakpoint-between(lg,xl) {
  .crunch-card {
    height: 450px;
    .card__content {
      .display-1 {
        font-size: 54px;
        line-height: 58px;
      }
      .h2 {
        font-size: 34px;
      }
    }
  }
  .meeting-rooms-card,
  .mailroom-card {
    height: 500px;
    .card__content {
      .display-1 {
        font-size: 54px;
        line-height: 58px;
      }
      .h2 {
        font-size: 34px;
      }
    }
  }
  .mailroom-card {
    height: 450px;
    .card__content {
      .lead__large {
        font-size: 18px;
      }
    }
  }
  .good-hands-card {
    height: 500px;
    margin-bottom: 8em;
    .card__content {
      .h1 {
        font-size: 44px;
        line-height: 52px;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .search-post-card {
    min-height: 520px;
    background: $gradient-red !important;
  }

  .plan-card-container {
    width: 100%;
    .col-lg-6 {
      &:last-child {
        display: flex;
        justify-content: center;
      }
    }
    img {
      position: initial;
      width: 250px;
      height: auto;
    }
    .btn {
      width: 138px;
    }
  }
  .robot-head-card {
    height: 700px;
  }
  .go-paperless-card {
    height: 615px;
  }
  .exclusive-partner-card {
    background: url('/images/banking/collect-page-10-mins-plugz-head-desktop.png') bottom right no-repeat $darkBlue;
    background-size: 100%;
    .h2 {
      font-size: 31px;
      line-height: 33px;
      letter-spacing: -1.55px;
    }
  }
  .card {
    &--padding-xl,.padding-xl {
      padding: 40px 20px 40px 20px;
    }
    &--padding-lg,.padding-lg {
      padding: 25px 30px 56px 30px;
    }
  }
  .crunch-card {
    height: 653px;
    .card__image {
      margin: -95px auto 0;
      max-width: 374px;
    }
    .card__content {
      padding: 55px 35px 0;
      .display-1 {
        font-size: 54px;
        line-height: 58px;
      }
    }
  }
  .mailroom-card {
    height: 739px;
  }
  .meeting-rooms-card {
    height: 695px;
    margin-bottom: 7em;
    .card__content {
      padding: 45px 24px 0 32px;
      .display-1 {
        font-size: 44px;
        line-height: 52px;
      }
      .h2 {
        font-size: 34px;
        line-height: 42px;
        letter-spacing: -1.7px;
      }
    }
  }
  .mailroom-card {
    height: 653px;
    .card__content {
      padding: 45px 45px 0 45px;
      justify-content: start !important;
    }
  }
  .good-hands-card {
    height: 739px;
    margin-bottom: 8.5em;
    .card__content {
      padding: 45px 45px 0 45px;
      justify-content: start !important;
      .h1 {
        font-weight: bold !important;
      }
    }
  }
  .card-select {
    padding: 30px 5px;
  }
  .card-with-vertically-centered-image {
    .vertically-centered-image {
      top: 250px;
      max-width: 50%;
    }
    .vertically-centered-image2 {
      position: initial;
      transform: translateY(0);
    }
  }
  .ten-minutes-card {
    &--option1 {
      min-height: 666px;
      height: initial;
    }
    &--option2 {
      min-height: 500px;
      height: initial;
    }
  }
  .plug-card {
    &.bg-gradient-purple-blue {
      background: url('/images/icons/hm-logo-crop-purple.svg') top left no-repeat, linear-gradient(125deg, #B82BAA 0%, #3EB7E4 100%);
      background-size: cover;
    }
  }
  .hoxtonmix-crunch-card {
    height: 280px;
    margin-bottom: 8em;
  }
}

@include media-breakpoint-down(md) {
  .meeting-rooms-card {
    height: 950px;
  }
  .card {
    &__image {
      max-width: 400px;
      //width: auto;
      height: auto;
      margin-right: auto;
      margin-left: auto;
    }
    &__min-height-256px {
      min-height: 256px;
      padding-top: 0;
      padding-bottom: 0;
      justify-content: center;
    }
    &--padding-sm,.padding-sm {
      padding: 34px 10px 15px;
    }
  }
  .plug-card {
    background-size: contain;
    &__icon {
      width: 74px;
      height: auto;
    }
  }
  .card-select {
    padding: 25px 7px 15px;
    .best-proposal {
      border-top: 60px solid #1B71E3;
      border-left: 60px solid transparent;
      &__text {
        top: -57px;
        right: 5px;
        font-size: 11px;
        line-height: 11px;
        letter-spacing: -0.47px;
        width: 40px;
        span {
          font-size: 7px;
          letter-spacing: -0.33px;
        }
      }
      &__icon1 {
        top: -31px;
        right: 13px;
      }
      &__icon2 {
        top: -28px;
        right: 0;
        width: 12px;
      }
    }
  }
  .plan-card-container {
    img {
      height: auto;
      max-width: 305px;
      width: 75%;
    }
  }
}

@include media-breakpoint-down(sm) {
  .evolving-platform-card {
    min-height: 545px;
    background-size: cover;
    overflow: hidden;
  }
  .exclusive-partner-card {
    min-height: 415px;
  }
  .robot-head-card {
    height: 408px;
    .robot-head {
      &--option1 {
        bottom: 20px;
        transform: scale(1.25);
        -ms-transform: scale(1.25);
        -webkit-transform: scale(1.25);
      }
    }
  }
  .ten-minutes-card {
    &--option1 {
      min-height: 486px;
    }
    &--option2 {
      min-height: 406px;
    }
    &--large {
      min-height: 443px;
      .robot-head {
        bottom: -7px;
        transform: scale(1);
        -ms-transform: scale(1);
        -webkit-transform: scale(1);
      }
    }
  }
  .card {
    &__image {
      max-width: 100%;
    }
  }
  .plug-card {
    .display-1 {
      font-size: 40px;
      line-height: 42px;
      letter-spacing: -2px;
    }
    &.evolving-platform-card {
      background: url(/images/icons/plug.png) top left no-repeat, linear-gradient(100deg, #8530F6 0%, #BC2CC0 100%);
    }
  }
  .card-with-vertically-centered-image {
    .vertically-centered-image {
      max-width: 375px;
      top: auto;
      bottom: -240px;
    }
  }
  .reviews-section {
    .card {
      &--padding-md,
      &.padding-md {
        padding: 33px 40px;
      }
    }
  }
  .virtual-phone-number-travels-card {
    margin-top: 27em;
  }
}
/*! purgecss end ignore */

@include media-breakpoint-down(xs) {
  .mailroom-card {
    height: 739px;
  }
  .meeting-rooms-card {
    height: 895px;
  }
  .go-paperless-card {
    height: 559px;
  }
}
