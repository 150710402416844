.other-community-pages {
  &__item {
    background: #fff;
    border-radius: 15px;
    h4 {
      font-size: 27px;
      letter-spacing: -1.35px;
    }
    .img-container {
      max-height: 270px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
  }
}

@include media-breakpoint-down(xxl) {
  .other-community-pages {
    &__item {
      .img-container {
        max-height: 250px;
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .other-community-pages {
    &__item {
      .img-container {
        max-height: 210px;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .other-community-pages {
    &__item {
      h4 {
        font-size: 23px;
        letter-spacing: -1.15px;
      }
      .img-container {
        max-height: 450px;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .other-community-pages {
    &__item {
      .img-container {
        max-height: 235px;
      }
    }
  }
}
