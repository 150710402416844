input[type="text"],
input[type="tel"],
input[type="email"] {
  border-style: solid;
  border-width: 1px;
}

.input-group-xl {
  input {
    height: 52px;
    border-radius: $btn-border-radius;
    font-size: 18px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

textarea {
  height: 188px;

  &[name="notes"] {
    height: 100px;
  }
}

.invalid {
  input[type="text"],
  input[type="tel"],
  input[type="email"] {
    border-color: $danger;
    border-width: 2px;
  }
  textarea{
    border-color: $danger;
    border-width: 2px;
  }
}

.form-label {
  font-size: 15px;
  letter-spacing: -0.75px;
  font-weight: 600;
  margin-bottom: 7px;
}

.form-error-pipe{
  background-color: $danger;
  height:auto;
  margin-right: 6px;
  width: 2px;
}

.react-select {
  font-size: 14px;
  letter-spacing: -0.35px;

  &__menu {
    font-size: 14px;
    letter-spacing: -0.35px;
  }

  &--without-separator {
    .css-1u9des2-indicatorSeparator {
      width: 0;
    }
  }
}

.form-control {
  font-size: 14px;
  letter-spacing: -0.35px;
  border: 1px solid $grey5;
  border-radius: 5px;
  padding: 10px 10px 9.4px;
}

.input {
  &__left-icon {
    position: absolute;
    top: 48px;
    left: 25px;
  }
}

.react-select__indicator-separator {
  margin: 0px !important;
  color: $grey5 !important;
  background-color: $grey5 !important;
}

.react-select-indicatorContainer {
  color: $black !important;
}

.react-select__control {
  border: 1px solid $grey5 !important;
  min-height: 42px !important;
}

.react-select--focused {
  min-height: 42px !important;
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
}

.react-select__dropdown-indicator {
  color: $grey5 !important;
}

.react-select__option--is-selected {
  background-color: $grey2 !important;
  color: $black !important;
}

.autocomplete {
  &__list {
    position: absolute;
    border: 1px solid $grey5;
    border-top: none;
    max-height: 247px;
    font-size: 14px;
    overflow-y: auto;
    left: 0;
    right: 0;
    background-color: white;
    border-radius: 0 0 5px 5px;
    z-index: 2;
    &--loading {
      color: $text-muted;
    }
  }
  &__item {
    border-bottom: 1px solid $grey5;
    padding: 10px;
    &:hover {
      background-color: #DEEBFF;
    }
  }
  &__input {
    border-radius: 0;
    input {
      &:focus {
        outline: none;
        box-shadow: none;
        border-color: $grey5;
      }
    }
  }
  &--open {
    input {
      border-radius: 5px 5px 0 0;
    }
  }
}

.autocomplete-last-item {
  border-bottom: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px;
  gap: 14px;
}

@include media-breakpoint-down(lg) {
  .form-control {
    font-size: 13px;
    padding: 9px 10px 8.2px;
  }
  .react-select__control {
    min-height: 38px !important;
  }
  textarea {
    &[name="notes"] {
      height: 85px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .autocomplete-last-item {
    gap: 5px;
    padding: 10px 9px 11px;
    justify-content: space-between;
  }
}

.action-button {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  margin: auto 0;
  &--not-visible {
    opacity: 0;
    pointer-events: none;
    @include transition(opacity 300ms);
  }
  @include transition(opacity 300ms);
  &--focused {
    pointer-events: auto;
    opacity: 1;
  }
}

.checkbox-label {
  font-weight: 600;
  padding-left: 30px;
  position: relative;
  width: 100%;
  input[type="radio"] {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto 0;
    height: 20px;
    width: 20px;
    opacity: 0;
    &:checked ~ .checkmark {
      background-color: $primary;
      border: 1px solid $primary;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #fff;
      }
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto 0;
    height: 20px;
    width: 20px;
    border: 1px solid #707070;
    border-radius: 50%;
  }
}
